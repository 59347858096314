import { useAbortController } from '@/hooks';
import { cn } from '@/lib/utils';
import { Button } from '@components/Button';
import { Typography } from '@components/Typography';
import { FC, useState } from 'react';

type DeleteModalContentProps = {
  entity: string;
  onSubmit: (signal: AbortSignal | undefined) => Promise<void>;
  onSuccess?: () => Promise<void>;
  onClose: VoidFunction;
};

export const DeleteModalContent: FC<DeleteModalContentProps> = ({ entity, onSubmit, onSuccess, onClose }) => {
  const { signal } = useAbortController();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await onSubmit(signal);
      await onSuccess?.();
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <div className="flex flex-col items-center gap-5 text-center">
      <Typography
        text={'deleteEntity'}
        interpolationsParams={{ entity }}
        variant="doubleXl/bold"
        className={cn('capitalize text-grey-900')}
      />
      <Typography
        text={'areYouSure'}
        variant="lg/regular"
        className={cn('whitespace-pre-wrap text-grey-900 first-letter:capitalize')}
      />
      <footer className="mt-4 grid h-12 w-full grid-cols-2 gap-4 *:h-full *:w-full *:items-center">
        <Button type="reset" onClick={onClose} intent={'secondary'} text="cancel" />
        <Button isLoading={isLoading} disabled={isLoading} onClick={handleSubmit} intent={'delete'} text="yesDelete" />
      </footer>
    </div>
  );
};
