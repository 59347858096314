import { useAbortController } from '@/hooks';
import { cn } from '@/lib/utils';
import { useDeepDiveDetails } from '@/modules/DeepDive/hooks/useDeepDiveDetails';
import { Loadable } from '@global/components/Loadable';
import { TileLayout } from '@global/components/TileLayout';
import { FC } from 'react';
import { DeepDiveInfoBlock } from './parts';

type DeepDiveInformationProps = {};

export const DeepDiveInformation: FC<DeepDiveInformationProps> = ({}) => {
  const { signal } = useAbortController();

  const { deepDive, isDeepDiveLoading } = useDeepDiveDetails({ signal });

  return (
    <TileLayout className={cn('grid h-32 grid-cols-[1fr,1fr,20%] gap-x-10', isDeepDiveLoading ? 'flex' : undefined)}>
      <Loadable
        isLoading={isDeepDiveLoading}
        spinnerProps={{
          size: 'lg',
        }}
      >
        <DeepDiveInfoBlock
          header={{
            text: 'deepDiveTask',
          }}
          headerTooltip={'deepDiveTaskTooltip'}
          content={deepDive?.task as string}
          headerClassName="capitalize"
        />
        <DeepDiveInfoBlock
          header={{
            text: 'referenceList',
          }}
          content={deepDive?.linkedUserListName as string}
        />
        <DeepDiveInfoBlock
          header={{
            text: 'numberOfCompanies',
          }}
          content={deepDive?.companyCount.toString() as string}
        />
      </Loadable>
    </TileLayout>
  );
};
