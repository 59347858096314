import { api } from '@/api/apiV2';
import { InitiateFileDownloadArgs } from './types';

const setupUrl = (url: string) => (url.startsWith('/') ? `api${url}` : `api/${url}`);

const initiateFileDownload = async ({ getBlobRequest }: InitiateFileDownloadArgs) => {
  const response = await getBlobRequest();

  // Ensure response is treated as a Blob
  const contentType = response.headers['content-type'] || 'application/octet-stream';
  const blob = new Blob([response.data as BlobPart], { type: contentType });
  const anchorUrl = window.URL.createObjectURL(blob);

  const anchor = document.createElement('a');
  anchor.href = anchorUrl;

  // Extract the filename from the Content-Disposition header
  const disposition = response.headers['content-disposition'];
  let filename = 'download.xlsx'; // Fallback filename

  if (disposition && disposition.includes('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches?.[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  // Clean up
  window.URL.revokeObjectURL(anchorUrl);
};

type DownloadFileArgs = {
  url: string;
  queryParams?: Record<string, string | null | undefined>;
  signal?: AbortSignal;
};

export const downloadFile = async ({ url, queryParams, signal }: DownloadFileArgs) => {
  const getBlobRequest = () =>
    api.http.request({ path: setupUrl(url), query: queryParams, format: 'blob', method: 'get', secure: true, signal });

  await initiateFileDownload({
    getBlobRequest,
  });
};
