import { QueryKeys } from '@/api/entities/queryKeys';
import { DeepDiveStatus } from '@/api/generated/Api';
import { useAbortController } from '@/hooks';
import { deleteDeepDive } from '@/modules/DeepDive/api';
import { useDeepDiveDetails } from '@/modules/DeepDive/hooks/useDeepDiveDetails';
import { RoutePath } from '@/router/constants';
import { SvgIcon } from '@components/SvgIcon';
import { Tooltip } from '@components/Tooltip';
import { DownloadButton } from '@global/components/Buttons';
import { DeleteButton } from '@global/components/Buttons/DeleteButton/DeleteButton';
import { Loadable } from '@global/components/Loadable';
import { PageTitle } from '@global/components/pages/PageTitle';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export const DeepDiveDetailsHeader: FC = () => {
  const navigate = useNavigate();
  const { signal } = useAbortController();
  const queryClient = useQueryClient();

  const { deepDive, isDeepDiveLoading } = useDeepDiveDetails({ signal });

  const onDelete = async () => {
    await queryClient.invalidateQueries({
      queryKey: [QueryKeys.DeepDives],
    });
    queryClient.removeQueries({
      queryKey: [QueryKeys.DeepDiveDetails, deepDive?.id],
    });

    navigate(RoutePath.DEEP_DIVES);
  };

  return (
    <header className="flex justify-between gap-4">
      <Loadable
        isLoading={isDeepDiveLoading}
        spinnerProps={{
          size: 'lg',
        }}
      >
        <div className="grid grid-cols-[1fr,repeat(2,max-content)] items-center gap-2">
          <Link to={RoutePath.DEEP_DIVES}>
            <PageTitle text={'deepDive'} className="truncate" />
          </Link>
          <PageTitle
            text={'deepDiveDetails'}
            interpolationsParams={{ name: deepDive?.name as string }}
            className="truncate"
          />
          <Tooltip content="whatIsDeepDive" side="right" align="start">
            <SvgIcon name="HelpCircleGradientIcon" />
          </Tooltip>
        </div>
        <div className="flex shrink-0 items-center gap-[18px]">
          <DeleteButton
            entity="deep dive"
            deleteFunc={deleteDeepDive(deepDive?.id as string)}
            onSuccess={onDelete}
            disabled={deepDive?.status === DeepDiveStatus.InProgress}
            tooltip="youCantDeleteDeepDive"
          />
          <DownloadButton url={`/deep-dives/${deepDive?.id}/export-to-excel`} text={{ text: 'downloadOutput' }} />
        </div>
      </Loadable>
    </header>
  );
};
