/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Account {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  businessProfile?: AccountBusinessProfile | null;
  businessType?: string | null;
  capabilities?: AccountCapabilities | null;
  chargesEnabled: boolean;
  company?: AccountCompany | null;
  controller?: AccountController | null;
  country?: string | null;
  /** @format date-time */
  created: string;
  defaultCurrency?: string | null;
  deleted?: boolean | null;
  detailsSubmitted: boolean;
  email?: string | null;
  externalAccounts?: IExternalAccount[] | null;
  futureRequirements?: AccountFutureRequirements | null;
  individual?: Person | null;
  metadata?: Record<string, string | null>;
  payoutsEnabled: boolean;
  requirements?: AccountRequirements | null;
  settings?: AccountSettings | null;
  tosAcceptance?: AccountTosAcceptance | null;
  type?: string | null;
}

export interface AccountBusinessProfile {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  annualRevenue?: AccountBusinessProfileAnnualRevenue | null;
  /** @format int64 */
  estimatedWorkerCount?: number | null;
  mcc?: string | null;
  monthlyEstimatedRevenue?: AccountBusinessProfileMonthlyEstimatedRevenue | null;
  name?: string | null;
  productDescription?: string | null;
  supportAddress?: Address | null;
  supportEmail?: string | null;
  supportPhone?: string | null;
  supportUrl?: string | null;
  url?: string | null;
}

export interface AccountBusinessProfileAnnualRevenue {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount?: number | null;
  currency?: string | null;
  fiscalYearEnd?: string | null;
}

export interface AccountBusinessProfileMonthlyEstimatedRevenue {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  currency?: string | null;
}

export interface AccountCapabilities {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  acssDebitPayments?: string | null;
  affirmPayments?: string | null;
  afterpayClearpayPayments?: string | null;
  amazonPayPayments?: string | null;
  auBecsDebitPayments?: string | null;
  bacsDebitPayments?: string | null;
  bancontactPayments?: string | null;
  bankTransferPayments?: string | null;
  blikPayments?: string | null;
  boletoPayments?: string | null;
  cardIssuing?: string | null;
  cardPayments?: string | null;
  cartesBancairesPayments?: string | null;
  cashappPayments?: string | null;
  epsPayments?: string | null;
  fpxPayments?: string | null;
  gbBankTransferPayments?: string | null;
  giropayPayments?: string | null;
  grabpayPayments?: string | null;
  idealPayments?: string | null;
  indiaInternationalPayments?: string | null;
  jcbPayments?: string | null;
  jpBankTransferPayments?: string | null;
  klarnaPayments?: string | null;
  konbiniPayments?: string | null;
  legacyPayments?: string | null;
  linkPayments?: string | null;
  mobilepayPayments?: string | null;
  multibancoPayments?: string | null;
  mxBankTransferPayments?: string | null;
  oxxoPayments?: string | null;
  p24Payments?: string | null;
  paynowPayments?: string | null;
  promptpayPayments?: string | null;
  revolutPayPayments?: string | null;
  sepaBankTransferPayments?: string | null;
  sepaDebitPayments?: string | null;
  sofortPayments?: string | null;
  swishPayments?: string | null;
  taxReportingUs1099K?: string | null;
  taxReportingUs1099Misc?: string | null;
  transfers?: string | null;
  treasury?: string | null;
  twintPayments?: string | null;
  usBankAccountAchPayments?: string | null;
  usBankTransferPayments?: string | null;
  zipPayments?: string | null;
}

export interface AccountCompany {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  address?: Address | null;
  addressKana?: AddressJapan | null;
  addressKanji?: AddressJapan | null;
  directorsProvided: boolean;
  executivesProvided: boolean;
  exportLicenseId?: string | null;
  exportPurposeCode?: string | null;
  name?: string | null;
  nameKana?: string | null;
  nameKanji?: string | null;
  ownersProvided: boolean;
  ownershipDeclaration?: AccountCompanyOwnershipDeclaration | null;
  phone?: string | null;
  structure?: string | null;
  taxIdProvided: boolean;
  taxIdRegistrar?: string | null;
  vatIdProvided: boolean;
  verification?: AccountCompanyVerification | null;
}

export interface AccountCompanyOwnershipDeclaration {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  date?: string | null;
  ip?: string | null;
  userAgent?: string | null;
}

export interface AccountCompanyVerification {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  document?: AccountCompanyVerificationDocument | null;
}

export interface AccountCompanyVerificationDocument {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  backId?: string | null;
  back?: File | null;
  details?: string | null;
  detailsCode?: string | null;
  frontId?: string | null;
  front?: File | null;
}

export interface AccountController {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  fees?: AccountControllerFees | null;
  isController: boolean;
  losses?: AccountControllerLosses | null;
  requirementCollection?: string | null;
  stripeDashboard?: AccountControllerStripeDashboard | null;
  type?: string | null;
}

export interface AccountControllerFees {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  payer?: string | null;
}

export interface AccountControllerLosses {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  payments?: string | null;
}

export interface AccountControllerStripeDashboard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  type?: string | null;
}

export interface AccountFutureRequirements {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  alternatives?: AccountFutureRequirementsAlternative[] | null;
  /** @format date-time */
  currentDeadline?: string | null;
  currentlyDue?: string[] | null;
  disabledReason?: string | null;
  errors?: AccountFutureRequirementsError[] | null;
  eventuallyDue?: string[] | null;
  pastDue?: string[] | null;
  pendingVerification?: string[] | null;
}

export interface AccountFutureRequirementsAlternative {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  alternativeFieldsDue?: string[] | null;
  originalFieldsDue?: string[] | null;
}

export interface AccountFutureRequirementsError {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  code?: string | null;
  reason?: string | null;
  requirement?: string | null;
}

export interface AccountRequirements {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  alternatives?: AccountRequirementsAlternative[] | null;
  /** @format date-time */
  currentDeadline?: string | null;
  currentlyDue?: string[] | null;
  disabledReason?: string | null;
  errors?: AccountRequirementsError[] | null;
  eventuallyDue?: string[] | null;
  pastDue?: string[] | null;
  pendingVerification?: string[] | null;
}

export interface AccountRequirementsAlternative {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  alternativeFieldsDue?: string[] | null;
  originalFieldsDue?: string[] | null;
}

export interface AccountRequirementsError {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  code?: string | null;
  reason?: string | null;
  requirement?: string | null;
}

export interface AccountSettings {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bacsDebitPayments?: AccountSettingsBacsDebitPayments | null;
  branding?: AccountSettingsBranding | null;
  cardIssuing?: AccountSettingsCardIssuing | null;
  cardPayments?: AccountSettingsCardPayments | null;
  dashboard?: AccountSettingsDashboard | null;
  invoices?: AccountSettingsInvoices | null;
  payments?: AccountSettingsPayments | null;
  payouts?: AccountSettingsPayouts | null;
  sepaDebitPayments?: AccountSettingsSepaDebitPayments | null;
  treasury?: AccountSettingsTreasury | null;
}

export interface AccountSettingsBacsDebitPayments {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  displayName?: string | null;
  serviceUserNumber?: string | null;
}

export interface AccountSettingsBranding {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  iconId?: string | null;
  icon?: File | null;
  logoId?: string | null;
  logo?: File | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
}

export interface AccountSettingsCardIssuing {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  tosAcceptance?: AccountSettingsCardIssuingTosAcceptance | null;
}

export interface AccountSettingsCardIssuingTosAcceptance {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  date?: string | null;
  ip?: string | null;
  userAgent?: string | null;
}

export interface AccountSettingsCardPayments {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  declineOn?: AccountSettingsDeclineOn | null;
  statementDescriptorPrefix?: string | null;
  statementDescriptorPrefixKana?: string | null;
  statementDescriptorPrefixKanji?: string | null;
}

export interface AccountSettingsDashboard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  displayName?: string | null;
  timezone?: string | null;
}

export interface AccountSettingsDeclineOn {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  avsFailure: boolean;
  cvcFailure: boolean;
}

export interface AccountSettingsInvoices {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  defaultAccountTaxIdIds?: string[] | null;
  defaultAccountTaxIds?: TaxId[] | null;
}

export interface AccountSettingsPayments {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  statementDescriptor?: string | null;
  statementDescriptorKana?: string | null;
  statementDescriptorKanji?: string | null;
  statementDescriptorPrefixKana?: string | null;
  statementDescriptorPrefixKanji?: string | null;
}

export interface AccountSettingsPayouts {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  debitNegativeBalances: boolean;
  schedule?: AccountSettingsPayoutsSchedule | null;
  statementDescriptor?: string | null;
}

export interface AccountSettingsPayoutsSchedule {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  delayDays: number;
  interval?: string | null;
  /** @format int64 */
  monthlyAnchor: number;
  weeklyAnchor?: string | null;
}

export interface AccountSettingsSepaDebitPayments {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  creditorId?: string | null;
}

export interface AccountSettingsTreasury {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  tosAcceptance?: AccountSettingsTreasuryTosAcceptance | null;
}

export interface AccountSettingsTreasuryTosAcceptance {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  date?: string | null;
  ip?: string | null;
  userAgent?: string | null;
}

export interface AccountTosAcceptance {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  date?: string | null;
  ip?: string | null;
  serviceAgreement?: string | null;
  userAgent?: string | null;
}

export interface AddCompaniesToUserListRequest {
  companyIds: string[];
  userListIds: string[];
  /** @format uuid */
  userId: string;
}

export interface Address {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  city?: string | null;
  country?: string | null;
  line1?: string | null;
  line2?: string | null;
  postalCode?: string | null;
  state?: string | null;
}

export interface AddressJapan {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  city?: string | null;
  country?: string | null;
  line1?: string | null;
  line2?: string | null;
  postalCode?: string | null;
  state?: string | null;
  town?: string | null;
}

export interface Application {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  deleted?: boolean | null;
  name?: string | null;
}

export interface ApplicationFee {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  accountId?: string | null;
  account?: Account | null;
  /** @format int64 */
  amount: number;
  /** @format int64 */
  amountRefunded: number;
  applicationId?: string | null;
  application?: Application | null;
  balanceTransactionId?: string | null;
  balanceTransaction?: BalanceTransaction | null;
  chargeId?: string | null;
  charge?: Charge | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  feeSource?: ApplicationFeeFeeSource | null;
  livemode: boolean;
  originatingTransactionId?: string | null;
  originatingTransaction?: Charge | null;
  refunded: boolean;
  refunds?: ApplicationFeeRefund[] | null;
}

export interface ApplicationFeeFeeSource {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  charge?: string | null;
  payout?: string | null;
  type?: string | null;
}

export interface ApplicationFeeRefund {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format int64 */
  amount: number;
  balanceTransactionId?: string | null;
  balanceTransaction?: BalanceTransaction | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  feeId?: string | null;
  fee?: ApplicationFee | null;
  metadata?: Record<string, string | null>;
}

export interface BalanceTransaction {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format int64 */
  amount: number;
  /** @format date-time */
  availableOn: string;
  /** @format date-time */
  created: string;
  currency?: string | null;
  description?: string | null;
  /** @format double */
  exchangeRate?: number | null;
  /** @format int64 */
  fee: number;
  feeDetails?: BalanceTransactionFeeDetail[] | null;
  /** @format int64 */
  net: number;
  reportingCategory?: string | null;
  sourceId?: string | null;
  source?: IBalanceTransactionSource | null;
  status?: string | null;
  type?: string | null;
}

export interface BalanceTransactionFeeDetail {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  application?: string | null;
  currency?: string | null;
  description?: string | null;
  type?: string | null;
}

export interface BaseFilter {
  /** @format int32 */
  foundedFrom?: number | null;
  /** @format int32 */
  foundedTo?: number | null;
  /** @format double */
  scoreFrom?: number | null;
  /** @format double */
  scoreTo?: number | null;
  /** @format int32 */
  limit: number;
  country?: string | null;
  companySize: string[];
  foundingStages: string[];
  tags: string[];
  isFoundingInfoExist: boolean;
  entityType?: string | null;
  entityTypeL2?: string | null;
  targetMarkets: string[];
  isVectorSearch: boolean;
}

export interface CashBalance {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  object?: string | null;
  available?: Record<string, number | null>;
  customer?: string | null;
  livemode: boolean;
  settings?: CashBalanceSettings | null;
}

export interface CashBalanceSettings {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reconciliationMode?: string | null;
  usingMerchantDefault: boolean;
}

export interface Charge {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format int64 */
  amount: number;
  /** @format int64 */
  amountCaptured: number;
  /** @format int64 */
  amountRefunded: number;
  applicationId?: string | null;
  application?: Application | null;
  applicationFeeId?: string | null;
  applicationFee?: ApplicationFee | null;
  /** @format int64 */
  applicationFeeAmount?: number | null;
  authorizationCode?: string | null;
  balanceTransactionId?: string | null;
  balanceTransaction?: BalanceTransaction | null;
  billingDetails?: ChargeBillingDetails | null;
  calculatedStatementDescriptor?: string | null;
  captured: boolean;
  /** @format date-time */
  created: string;
  currency?: string | null;
  customerId?: string | null;
  customer?: Customer | null;
  description?: string | null;
  disputed: boolean;
  failureBalanceTransactionId?: string | null;
  failureBalanceTransaction?: BalanceTransaction | null;
  failureCode?: string | null;
  failureMessage?: string | null;
  fraudDetails?: ChargeFraudDetails | null;
  invoiceId?: string | null;
  invoice?: Invoice | null;
  level3?: ChargeLevel3 | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  onBehalfOfId?: string | null;
  onBehalfOf?: Account | null;
  outcome?: ChargeOutcome | null;
  paid: boolean;
  paymentIntentId?: string | null;
  paymentIntent?: PaymentIntent | null;
  paymentMethod?: string | null;
  paymentMethodDetails?: ChargePaymentMethodDetails | null;
  radarOptions?: ChargeRadarOptions | null;
  receiptEmail?: string | null;
  receiptNumber?: string | null;
  receiptUrl?: string | null;
  refunded: boolean;
  refunds?: Refund[] | null;
  reviewId?: string | null;
  review?: Review | null;
  shipping?: Shipping | null;
  source?: IPaymentSource | null;
  sourceTransferId?: string | null;
  sourceTransfer?: Transfer | null;
  statementDescriptor?: string | null;
  statementDescriptorSuffix?: string | null;
  status?: string | null;
  transferId?: string | null;
  transfer?: Transfer | null;
  transferData?: ChargeTransferData | null;
  transferGroup?: string | null;
}

export interface ChargeBillingDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  address?: Address | null;
  email?: string | null;
  name?: string | null;
  phone?: string | null;
}

export interface ChargeFraudDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  stripeReport?: string | null;
  userReport?: string | null;
}

export interface ChargeLevel3 {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  customerReference?: string | null;
  lineItems?: ChargeLevel3LineItem[] | null;
  merchantReference?: string | null;
  shippingAddressZip?: string | null;
  /** @format int64 */
  shippingAmount: number;
  shippingFromZip?: string | null;
}

export interface ChargeLevel3LineItem {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  discountAmount?: number | null;
  productCode?: string | null;
  productDescription?: string | null;
  /** @format int64 */
  quantity?: number | null;
  /** @format int64 */
  taxAmount?: number | null;
  /** @format int64 */
  unitCost?: number | null;
}

export interface ChargeOutcome {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  networkStatus?: string | null;
  reason?: string | null;
  riskLevel?: string | null;
  /** @format int64 */
  riskScore: number;
  ruleId?: string | null;
  rule?: Rule | null;
  sellerMessage?: string | null;
  type?: string | null;
}

export interface ChargePaymentMethodDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  achCreditTransfer?: ChargePaymentMethodDetailsAchCreditTransfer | null;
  achDebit?: ChargePaymentMethodDetailsAchDebit | null;
  acssDebit?: ChargePaymentMethodDetailsAcssDebit | null;
  affirm?: ChargePaymentMethodDetailsAffirm | null;
  afterpayClearpay?: ChargePaymentMethodDetailsAfterpayClearpay | null;
  alipay?: ChargePaymentMethodDetailsAlipay | null;
  amazonPay?: ChargePaymentMethodDetailsAmazonPay | null;
  auBecsDebit?: ChargePaymentMethodDetailsAuBecsDebit | null;
  bacsDebit?: ChargePaymentMethodDetailsBacsDebit | null;
  bancontact?: ChargePaymentMethodDetailsBancontact | null;
  blik?: ChargePaymentMethodDetailsBlik | null;
  boleto?: ChargePaymentMethodDetailsBoleto | null;
  card?: ChargePaymentMethodDetailsCard | null;
  cardPresent?: ChargePaymentMethodDetailsCardPresent | null;
  cashapp?: ChargePaymentMethodDetailsCashapp | null;
  customerBalance?: ChargePaymentMethodDetailsCustomerBalance | null;
  eps?: ChargePaymentMethodDetailsEps | null;
  fpx?: ChargePaymentMethodDetailsFpx | null;
  giropay?: ChargePaymentMethodDetailsGiropay | null;
  grabpay?: ChargePaymentMethodDetailsGrabpay | null;
  ideal?: ChargePaymentMethodDetailsIdeal | null;
  interacPresent?: ChargePaymentMethodDetailsInteracPresent | null;
  klarna?: ChargePaymentMethodDetailsKlarna | null;
  konbini?: ChargePaymentMethodDetailsKonbini | null;
  link?: ChargePaymentMethodDetailsLink | null;
  mobilepay?: ChargePaymentMethodDetailsMobilepay | null;
  multibanco?: ChargePaymentMethodDetailsMultibanco | null;
  oxxo?: ChargePaymentMethodDetailsOxxo | null;
  p24?: ChargePaymentMethodDetailsP24 | null;
  paynow?: ChargePaymentMethodDetailsPaynow | null;
  paypal?: ChargePaymentMethodDetailsPaypal | null;
  pix?: ChargePaymentMethodDetailsPix | null;
  promptpay?: ChargePaymentMethodDetailsPromptpay | null;
  revolutPay?: ChargePaymentMethodDetailsRevolutPay | null;
  sepaDebit?: ChargePaymentMethodDetailsSepaDebit | null;
  sofort?: ChargePaymentMethodDetailsSofort | null;
  stripeAccount?: ChargePaymentMethodDetailsStripeAccount | null;
  swish?: ChargePaymentMethodDetailsSwish | null;
  twint?: ChargePaymentMethodDetailsTwint | null;
  type?: string | null;
  usBankAccount?: ChargePaymentMethodDetailsUsBankAccount | null;
  wechat?: ChargePaymentMethodDetailsWechat | null;
  wechatPay?: ChargePaymentMethodDetailsWechatPay | null;
  zip?: ChargePaymentMethodDetailsZip | null;
}

export interface ChargePaymentMethodDetailsAchCreditTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountNumber?: string | null;
  bankName?: string | null;
  routingNumber?: string | null;
  swiftCode?: string | null;
}

export interface ChargePaymentMethodDetailsAchDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountHolderType?: string | null;
  bankName?: string | null;
  country?: string | null;
  fingerprint?: string | null;
  last4?: string | null;
  routingNumber?: string | null;
}

export interface ChargePaymentMethodDetailsAcssDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankName?: string | null;
  fingerprint?: string | null;
  institutionNumber?: string | null;
  last4?: string | null;
  mandate?: string | null;
  transitNumber?: string | null;
}

export interface ChargePaymentMethodDetailsAffirm {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  transactionId?: string | null;
}

export interface ChargePaymentMethodDetailsAfterpayClearpay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  orderId?: string | null;
  reference?: string | null;
}

export interface ChargePaymentMethodDetailsAlipay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  buyerId?: string | null;
  fingerprint?: string | null;
  transactionId?: string | null;
}

export interface ChargePaymentMethodDetailsAmazonPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface ChargePaymentMethodDetailsAuBecsDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bsbNumber?: string | null;
  fingerprint?: string | null;
  last4?: string | null;
  mandate?: string | null;
}

export interface ChargePaymentMethodDetailsBacsDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  fingerprint?: string | null;
  last4?: string | null;
  mandate?: string | null;
  sortCode?: string | null;
}

export interface ChargePaymentMethodDetailsBancontact {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankCode?: string | null;
  bankName?: string | null;
  bic?: string | null;
  generatedSepaDebitId?: string | null;
  generatedSepaDebit?: PaymentMethod | null;
  generatedSepaDebitMandateId?: string | null;
  generatedSepaDebitMandate?: Mandate | null;
  ibanLast4?: string | null;
  preferredLanguage?: string | null;
  verifiedName?: string | null;
}

export interface ChargePaymentMethodDetailsBlik {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  buyerId?: string | null;
}

export interface ChargePaymentMethodDetailsBoleto {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  taxId?: string | null;
}

export interface ChargePaymentMethodDetailsCard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountAuthorized?: number | null;
  authorizationCode?: string | null;
  brand?: string | null;
  /** @format date-time */
  captureBefore: string;
  checks?: ChargePaymentMethodDetailsCardChecks | null;
  country?: string | null;
  description?: string | null;
  /** @format int64 */
  expMonth: number;
  /** @format int64 */
  expYear: number;
  extendedAuthorization?: ChargePaymentMethodDetailsCardExtendedAuthorization | null;
  fingerprint?: string | null;
  funding?: string | null;
  iin?: string | null;
  incrementalAuthorization?: ChargePaymentMethodDetailsCardIncrementalAuthorization | null;
  installments?: ChargePaymentMethodDetailsCardInstallments | null;
  issuer?: string | null;
  last4?: string | null;
  mandate?: string | null;
  moto?: boolean | null;
  multicapture?: ChargePaymentMethodDetailsCardMulticapture | null;
  network?: string | null;
  networkToken?: ChargePaymentMethodDetailsCardNetworkToken | null;
  overcapture?: ChargePaymentMethodDetailsCardOvercapture | null;
  threeDSecure?: ChargePaymentMethodDetailsCardThreeDSecure | null;
  wallet?: ChargePaymentMethodDetailsCardWallet | null;
}

export interface ChargePaymentMethodDetailsCardChecks {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  addressLine1Check?: string | null;
  addressPostalCodeCheck?: string | null;
  cvcCheck?: string | null;
}

export interface ChargePaymentMethodDetailsCardExtendedAuthorization {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  status?: string | null;
}

export interface ChargePaymentMethodDetailsCardIncrementalAuthorization {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  status?: string | null;
}

export interface ChargePaymentMethodDetailsCardInstallments {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  plan?: PaymentIntentPaymentMethodOptionsCardInstallmentsPlan | null;
}

export interface ChargePaymentMethodDetailsCardMulticapture {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  status?: string | null;
}

export interface ChargePaymentMethodDetailsCardNetworkToken {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  used: boolean;
}

export interface ChargePaymentMethodDetailsCardOvercapture {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  maximumAmountCapturable: number;
  status?: string | null;
}

export interface ChargePaymentMethodDetailsCardPresent {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountAuthorized?: number | null;
  brand?: string | null;
  brandProduct?: string | null;
  /** @format date-time */
  captureBefore: string;
  cardholderName?: string | null;
  country?: string | null;
  description?: string | null;
  emvAuthData?: string | null;
  /** @format int64 */
  expMonth: number;
  /** @format int64 */
  expYear: number;
  fingerprint?: string | null;
  funding?: string | null;
  generatedCard?: string | null;
  iin?: string | null;
  incrementalAuthorizationSupported: boolean;
  issuer?: string | null;
  last4?: string | null;
  network?: string | null;
  networkTransactionId?: string | null;
  offline?: ChargePaymentMethodDetailsCardPresentOffline | null;
  overcaptureSupported: boolean;
  preferredLocales?: string[] | null;
  readMethod?: string | null;
  receipt?: ChargePaymentMethodDetailsCardPresentReceipt | null;
  wallet?: ChargePaymentMethodDetailsCardPresentWallet | null;
}

export interface ChargePaymentMethodDetailsCardPresentOffline {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  storedAt?: string | null;
  type?: string | null;
}

export interface ChargePaymentMethodDetailsCardPresentReceipt {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountType?: string | null;
  applicationCryptogram?: string | null;
  applicationPreferredName?: string | null;
  authorizationCode?: string | null;
  authorizationResponseCode?: string | null;
  cardholderVerificationMethod?: string | null;
  dedicatedFileName?: string | null;
  terminalVerificationResults?: string | null;
  transactionStatusInformation?: string | null;
}

export interface ChargePaymentMethodDetailsCardPresentWallet {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  type?: string | null;
}

export interface ChargePaymentMethodDetailsCardThreeDSecure {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  authenticationFlow?: string | null;
  electronicCommerceIndicator?: string | null;
  exemptionIndicator?: string | null;
  exemptionIndicatorApplied: boolean;
  result?: string | null;
  resultReason?: string | null;
  transactionId?: string | null;
  version?: string | null;
}

export interface ChargePaymentMethodDetailsCardWallet {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  amexExpressCheckout?: ChargePaymentMethodDetailsCardWalletAmexExpressCheckout | null;
  applePay?: ChargePaymentMethodDetailsCardWalletApplePay | null;
  dynamicLast4?: string | null;
  googlePay?: ChargePaymentMethodDetailsCardWalletGooglePay | null;
  link?: ChargePaymentMethodDetailsCardWalletLink | null;
  masterpass?: ChargePaymentMethodDetailsCardWalletMasterpass | null;
  samsungPay?: ChargePaymentMethodDetailsCardWalletSamsungPay | null;
  type?: string | null;
  visaCheckout?: ChargePaymentMethodDetailsCardWalletVisaCheckout | null;
}

export interface ChargePaymentMethodDetailsCardWalletAmexExpressCheckout {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface ChargePaymentMethodDetailsCardWalletApplePay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface ChargePaymentMethodDetailsCardWalletGooglePay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface ChargePaymentMethodDetailsCardWalletLink {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface ChargePaymentMethodDetailsCardWalletMasterpass {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  billingAddress?: Address | null;
  email?: string | null;
  name?: string | null;
  shippingAddress?: Address | null;
}

export interface ChargePaymentMethodDetailsCardWalletSamsungPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface ChargePaymentMethodDetailsCardWalletVisaCheckout {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  billingAddress?: Address | null;
  email?: string | null;
  name?: string | null;
  shippingAddress?: Address | null;
}

export interface ChargePaymentMethodDetailsCashapp {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  buyerId?: string | null;
  cashtag?: string | null;
}

export interface ChargePaymentMethodDetailsCustomerBalance {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface ChargePaymentMethodDetailsEps {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bank?: string | null;
  verifiedName?: string | null;
}

export interface ChargePaymentMethodDetailsFpx {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountHolderType?: string | null;
  bank?: string | null;
  transactionId?: string | null;
}

export interface ChargePaymentMethodDetailsGiropay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankCode?: string | null;
  bankName?: string | null;
  bic?: string | null;
  verifiedName?: string | null;
}

export interface ChargePaymentMethodDetailsGrabpay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  transactionId?: string | null;
}

export interface ChargePaymentMethodDetailsIdeal {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bank?: string | null;
  bic?: string | null;
  generatedSepaDebitId?: string | null;
  generatedSepaDebit?: PaymentMethod | null;
  generatedSepaDebitMandateId?: string | null;
  generatedSepaDebitMandate?: Mandate | null;
  ibanLast4?: string | null;
  verifiedName?: string | null;
}

export interface ChargePaymentMethodDetailsInteracPresent {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  brand?: string | null;
  cardholderName?: string | null;
  country?: string | null;
  description?: string | null;
  emvAuthData?: string | null;
  /** @format int64 */
  expMonth: number;
  /** @format int64 */
  expYear: number;
  fingerprint?: string | null;
  funding?: string | null;
  generatedCard?: string | null;
  iin?: string | null;
  issuer?: string | null;
  last4?: string | null;
  network?: string | null;
  networkTransactionId?: string | null;
  preferredLocales?: string[] | null;
  readMethod?: string | null;
  receipt?: ChargePaymentMethodDetailsInteracPresentReceipt | null;
}

export interface ChargePaymentMethodDetailsInteracPresentReceipt {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountType?: string | null;
  applicationCryptogram?: string | null;
  applicationPreferredName?: string | null;
  authorizationCode?: string | null;
  authorizationResponseCode?: string | null;
  cardholderVerificationMethod?: string | null;
  dedicatedFileName?: string | null;
  terminalVerificationResults?: string | null;
  transactionStatusInformation?: string | null;
}

export interface ChargePaymentMethodDetailsKlarna {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  paymentMethodCategory?: string | null;
  preferredLocale?: string | null;
}

export interface ChargePaymentMethodDetailsKonbini {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  store?: ChargePaymentMethodDetailsKonbiniStore | null;
}

export interface ChargePaymentMethodDetailsKonbiniStore {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  chain?: string | null;
}

export interface ChargePaymentMethodDetailsLink {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  country?: string | null;
}

export interface ChargePaymentMethodDetailsMobilepay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  card?: ChargePaymentMethodDetailsMobilepayCard | null;
}

export interface ChargePaymentMethodDetailsMobilepayCard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  brand?: string | null;
  country?: string | null;
  /** @format int64 */
  expMonth?: number | null;
  /** @format int64 */
  expYear?: number | null;
  last4?: string | null;
}

export interface ChargePaymentMethodDetailsMultibanco {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  entity?: string | null;
  reference?: string | null;
}

export interface ChargePaymentMethodDetailsOxxo {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  number?: string | null;
}

export interface ChargePaymentMethodDetailsP24 {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bank?: string | null;
  reference?: string | null;
  verifiedName?: string | null;
}

export interface ChargePaymentMethodDetailsPaynow {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
}

export interface ChargePaymentMethodDetailsPaypal {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  payerEmail?: string | null;
  payerId?: string | null;
  payerName?: string | null;
  sellerProtection?: ChargePaymentMethodDetailsPaypalSellerProtection | null;
  transactionId?: string | null;
}

export interface ChargePaymentMethodDetailsPaypalSellerProtection {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  disputeCategories?: string[] | null;
  status?: string | null;
}

export interface ChargePaymentMethodDetailsPix {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankTransactionId?: string | null;
}

export interface ChargePaymentMethodDetailsPromptpay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
}

export interface ChargePaymentMethodDetailsRevolutPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface ChargePaymentMethodDetailsSepaDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankCode?: string | null;
  branchCode?: string | null;
  country?: string | null;
  fingerprint?: string | null;
  last4?: string | null;
  mandate?: string | null;
}

export interface ChargePaymentMethodDetailsSofort {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankCode?: string | null;
  bankName?: string | null;
  bic?: string | null;
  country?: string | null;
  generatedSepaDebitId?: string | null;
  generatedSepaDebit?: PaymentMethod | null;
  generatedSepaDebitMandateId?: string | null;
  generatedSepaDebitMandate?: Mandate | null;
  ibanLast4?: string | null;
  preferredLanguage?: string | null;
  verifiedName?: string | null;
}

export interface ChargePaymentMethodDetailsStripeAccount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface ChargePaymentMethodDetailsSwish {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  fingerprint?: string | null;
  paymentReference?: string | null;
  verifiedPhoneLast4?: string | null;
}

export interface ChargePaymentMethodDetailsTwint {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface ChargePaymentMethodDetailsUsBankAccount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountHolderType?: string | null;
  accountType?: string | null;
  bankName?: string | null;
  fingerprint?: string | null;
  last4?: string | null;
  mandateId?: string | null;
  mandate?: Mandate | null;
  paymentReference?: string | null;
  routingNumber?: string | null;
}

export interface ChargePaymentMethodDetailsWechat {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface ChargePaymentMethodDetailsWechatPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  fingerprint?: string | null;
  transactionId?: string | null;
}

export interface ChargePaymentMethodDetailsZip {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface ChargeRadarOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  session?: string | null;
}

export interface ChargeTransferData {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount?: number | null;
  destinationId?: string | null;
  destination?: Account | null;
}

export interface CompanyResponse {
  companyId: string;
  companyName: string;
  websiteUrl: string;
  logoUrl: string;
  countryName: string;
  shortDescription: string;
  longDescription: string;
  productsServices: string;
  targetClients: string;
  targetMarkets: string[];
  headquartersCity?: string | null;
  headquartersFullAddress?: string | null;
  /** @format int32 */
  foundedIn?: number | null;
  /** @format int64 */
  vatCode?: number | null;
  linkedinUrl: string;
  linkedinIdentifier: string;
  industry: string;
  employeeRange?: string | null;
  /** @format date-time */
  insertionDate?: string | null;
  /** @format double */
  score: number;
  crunchbaseHandle?: string | null;
  /** @format int32 */
  totalRounds?: number | null;
  lastRoundCrunchbaseHandle?: string | null;
  lastRoundType?: string | null;
  lastRoundDate?: string | null;
  lastRoundAmountRaised?: string | null;
  /** @format int32 */
  lastRoundNumberOfInvestors?: number | null;
  lastRoundInvestorsCrunchbaseHandle?: string | null;
  entityType?: string | null;
  entityTypeL2?: string | null;
  tags: string[];
  userLists: string[];
}

export interface CompanyResponsePaginatedResult {
  /** @format int64 */
  totalRecords: number;
  data?: CompanyResponse[] | null;
}

export interface Coupon {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format int64 */
  amountOff?: number | null;
  appliesTo?: CouponAppliesTo | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  currencyOptions?: Record<string, CouponCurrencyOptions>;
  deleted?: boolean | null;
  duration?: string | null;
  /** @format int64 */
  durationInMonths?: number | null;
  livemode: boolean;
  /** @format int64 */
  maxRedemptions?: number | null;
  metadata?: Record<string, string | null>;
  name?: string | null;
  /** @format double */
  percentOff?: number | null;
  /** @format date-time */
  redeemBy?: string | null;
  /** @format int64 */
  timesRedeemed: number;
  valid: boolean;
}

export interface CouponAppliesTo {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  products?: string[] | null;
}

export interface CouponCurrencyOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountOff: number;
}

export interface CreateDeepDiveDetailsRequest {
  status: DeepDiveStatus;
  items: CreateDeepDiveItemsRequest[];
}

export interface CreateDeepDiveItemsRequest {
  companyId: string;
  aiReasoning: string;
  status: DeepDiveItemStatus;
  description: string;
}

export interface CreateDeepDiveRequest {
  name: string;
  /** @format uuid */
  userListId: string;
  task: string;
}

export interface Customer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  address?: Address | null;
  /** @format int64 */
  balance: number;
  cashBalance?: CashBalance | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  defaultSourceId?: string | null;
  defaultSource?: IPaymentSource | null;
  deleted?: boolean | null;
  delinquent?: boolean | null;
  description?: string | null;
  discount?: Discount | null;
  email?: string | null;
  invoiceCreditBalance?: Record<string, number | null>;
  invoicePrefix?: string | null;
  invoiceSettings?: CustomerInvoiceSettings | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  name?: string | null;
  /** @format int64 */
  nextInvoiceSequence: number;
  phone?: string | null;
  preferredLocales?: string[] | null;
  shipping?: Shipping | null;
  sources?: IPaymentSource[] | null;
  subscriptions?: Subscription[] | null;
  tax?: CustomerTax | null;
  taxExempt?: string | null;
  taxIds?: TaxId[] | null;
  testClockId?: string | null;
  testClock?: TestClock | null;
}

export interface CustomerInvoiceSettings {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  customFields?: CustomerInvoiceSettingsCustomField[] | null;
  defaultPaymentMethodId?: string | null;
  defaultPaymentMethod?: PaymentMethod | null;
  footer?: string | null;
  renderingOptions?: CustomerInvoiceSettingsRenderingOptions | null;
}

export interface CustomerInvoiceSettingsCustomField {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  name?: string | null;
  value?: string | null;
}

export interface CustomerInvoiceSettingsRenderingOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  amountTaxDisplay?: string | null;
}

export interface CustomerTax {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  automaticTax?: string | null;
  ipAddress?: string | null;
  location?: CustomerTaxLocation | null;
}

export interface CustomerTaxLocation {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  country?: string | null;
  source?: string | null;
  state?: string | null;
}

export interface DeepDiveItemResponse {
  /** @format uuid */
  id: string;
  companyId: string;
  aiReasoning: string;
  status: DeepDiveItemStatus;
  description: string;
  companyImageUrl: string;
  companyName: string;
}

export interface DeepDiveItemResponsePaginatedResult {
  /** @format int64 */
  totalRecords: number;
  data?: DeepDiveItemResponse[] | null;
}

export enum DeepDiveItemStatus {
  Unknown = 'Unknown',
  Suitable = 'Suitable',
  NotSuitable = 'NotSuitable',
  Error = 'Error',
}

export interface DeepDiveResponse {
  /** @format uuid */
  id: string;
  name: string;
  suitableResults: string;
  status: DeepDiveStatus;
  linkedUserListName: string;
  task: string;
  /** @format int32 */
  companyCount: number;
}

export interface DeepDiveResponsePaginatedResult {
  /** @format int64 */
  totalRecords: number;
  data?: DeepDiveResponse[] | null;
}

export enum DeepDiveStatus {
  Unknown = 'Unknown',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
}

export interface DeepDiveUserListResponse {
  /** @format uuid */
  id: string;
  name: string;
  /** @format int32 */
  cost: number;
}

export interface Discount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  checkoutSession?: string | null;
  coupon?: Coupon | null;
  customerId?: string | null;
  customer?: Customer | null;
  deleted?: boolean | null;
  /** @format date-time */
  end?: string | null;
  invoice?: string | null;
  invoiceItem?: string | null;
  promotionCodeId?: string | null;
  promotionCode?: PromotionCode | null;
  /** @format date-time */
  start: string;
  subscription?: string | null;
  subscriptionItem?: string | null;
}

export interface Dob {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  day?: number | null;
  /** @format int64 */
  month?: number | null;
  /** @format int64 */
  year?: number | null;
}

export interface EducationResponse {
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  subtitle: string;
  /** @minLength 1 */
  caption: string;
}

export interface ExperienceResponse {
  /** @minLength 1 */
  title: string;
  /** @minLength 1 */
  subtitle: string;
  /** @minLength 1 */
  caption: string;
}

export interface File {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format date-time */
  created: string;
  /** @format date-time */
  expiresAt?: string | null;
  filename?: string | null;
  links?: FileLink[] | null;
  purpose?: string | null;
  /** @format int64 */
  size: number;
  title?: string | null;
  type?: string | null;
  url?: string | null;
}

export interface FileLink {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format date-time */
  created: string;
  expired: boolean;
  /** @format date-time */
  expiresAt?: string | null;
  fileId?: string | null;
  file?: File | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  url?: string | null;
}

export interface Filter {
  /** @format int32 */
  foundedFrom?: number | null;
  /** @format int32 */
  foundedTo?: number | null;
  /** @format double */
  scoreFrom?: number | null;
  /** @format double */
  scoreTo?: number | null;
  /** @format int32 */
  limit: number;
  country?: string | null;
  companySize: string[];
  foundingStages: string[];
  tags: string[];
  isFoundingInfoExist: boolean;
  entityType?: string | null;
  entityTypeL2?: string | null;
  targetMarkets: string[];
  isVectorSearch: boolean;
  query?: string | null;
  title?: string | null;
  signalTypes?: SignalType[] | null;
  /** @format int32 */
  offset: number;
}

export interface FoundingInfoResponse {
  crunchbaseHandle?: string | null;
  /** @format int32 */
  totalRounds?: number | null;
  lastRoundCrunchbaseHandle?: string | null;
  lastRoundType?: string | null;
  lastRoundDate?: string | null;
  lastRoundAmountRaised?: string | null;
  /** @format int32 */
  lastRoundNumberOfInvestors?: number | null;
  lastRoundInvestorsCrunchbaseHandle?: string | null;
}

export interface GoogleLoginRequest {
  refreshToken: string;
  firstName: string;
  lastName: string;
}

export interface HeadquartersResponse {
  fullAddress?: string | null;
  city?: string | null;
}

export enum HttpStatusCode {
  Continue = 'Continue',
  SwitchingProtocols = 'SwitchingProtocols',
  Processing = 'Processing',
  EarlyHints = 'EarlyHints',
  OK = 'OK',
  Created = 'Created',
  Accepted = 'Accepted',
  NonAuthoritativeInformation = 'NonAuthoritativeInformation',
  NoContent = 'NoContent',
  ResetContent = 'ResetContent',
  PartialContent = 'PartialContent',
  MultiStatus = 'MultiStatus',
  AlreadyReported = 'AlreadyReported',
  IMUsed = 'IMUsed',
  MultipleChoices = 'MultipleChoices',
  MovedPermanently = 'MovedPermanently',
  Found = 'Found',
  SeeOther = 'SeeOther',
  NotModified = 'NotModified',
  UseProxy = 'UseProxy',
  Unused = 'Unused',
  TemporaryRedirect = 'TemporaryRedirect',
  PermanentRedirect = 'PermanentRedirect',
  BadRequest = 'BadRequest',
  Unauthorized = 'Unauthorized',
  PaymentRequired = 'PaymentRequired',
  Forbidden = 'Forbidden',
  NotFound = 'NotFound',
  MethodNotAllowed = 'MethodNotAllowed',
  NotAcceptable = 'NotAcceptable',
  ProxyAuthenticationRequired = 'ProxyAuthenticationRequired',
  RequestTimeout = 'RequestTimeout',
  Conflict = 'Conflict',
  Gone = 'Gone',
  LengthRequired = 'LengthRequired',
  PreconditionFailed = 'PreconditionFailed',
  RequestEntityTooLarge = 'RequestEntityTooLarge',
  RequestUriTooLong = 'RequestUriTooLong',
  UnsupportedMediaType = 'UnsupportedMediaType',
  RequestedRangeNotSatisfiable = 'RequestedRangeNotSatisfiable',
  ExpectationFailed = 'ExpectationFailed',
  MisdirectedRequest = 'MisdirectedRequest',
  UnprocessableEntity = 'UnprocessableEntity',
  Locked = 'Locked',
  FailedDependency = 'FailedDependency',
  UpgradeRequired = 'UpgradeRequired',
  PreconditionRequired = 'PreconditionRequired',
  TooManyRequests = 'TooManyRequests',
  RequestHeaderFieldsTooLarge = 'RequestHeaderFieldsTooLarge',
  UnavailableForLegalReasons = 'UnavailableForLegalReasons',
  InternalServerError = 'InternalServerError',
  NotImplemented = 'NotImplemented',
  BadGateway = 'BadGateway',
  ServiceUnavailable = 'ServiceUnavailable',
  GatewayTimeout = 'GatewayTimeout',
  HttpVersionNotSupported = 'HttpVersionNotSupported',
  VariantAlsoNegotiates = 'VariantAlsoNegotiates',
  InsufficientStorage = 'InsufficientStorage',
  LoopDetected = 'LoopDetected',
  NotExtended = 'NotExtended',
  NetworkAuthenticationRequired = 'NetworkAuthenticationRequired',
}

export interface IBalanceTransactionSource {
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
}

export interface IExternalAccount {
  account?: Account | null;
  accountId?: string | null;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
}

export interface IPaymentSource {
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
}

export interface Invoice {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  accountCountry?: string | null;
  accountName?: string | null;
  accountTaxIdIds?: string[] | null;
  accountTaxIds?: TaxId[] | null;
  /** @format int64 */
  amountDue: number;
  /** @format int64 */
  amountPaid: number;
  /** @format int64 */
  amountRemaining: number;
  /** @format int64 */
  amountShipping: number;
  applicationId?: string | null;
  application?: Application | null;
  /** @format int64 */
  applicationFeeAmount?: number | null;
  /** @format int64 */
  attemptCount: number;
  attempted: boolean;
  autoAdvance: boolean;
  automaticTax?: InvoiceAutomaticTax | null;
  billingReason?: string | null;
  chargeId?: string | null;
  charge?: Charge | null;
  collectionMethod?: string | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  customFields?: InvoiceCustomField[] | null;
  customerId?: string | null;
  customer?: Customer | null;
  customerAddress?: Address | null;
  customerEmail?: string | null;
  customerName?: string | null;
  customerPhone?: string | null;
  customerShipping?: Shipping | null;
  customerTaxExempt?: string | null;
  customerTaxIds?: InvoiceCustomerTaxId[] | null;
  defaultPaymentMethodId?: string | null;
  defaultPaymentMethod?: PaymentMethod | null;
  defaultSourceId?: string | null;
  defaultSource?: IPaymentSource | null;
  defaultTaxRates?: TaxRate[] | null;
  deleted?: boolean | null;
  description?: string | null;
  discount?: Discount | null;
  discountIds?: string[] | null;
  discounts?: Discount[] | null;
  /** @format date-time */
  dueDate?: string | null;
  /** @format date-time */
  effectiveAt?: string | null;
  /** @format int64 */
  endingBalance?: number | null;
  footer?: string | null;
  fromInvoice?: InvoiceFromInvoice | null;
  hostedInvoiceUrl?: string | null;
  invoicePdf?: string | null;
  issuer?: InvoiceIssuer | null;
  lastFinalizationError?: StripeError | null;
  latestRevisionId?: string | null;
  latestRevision?: Invoice | null;
  lines?: InvoiceLineItem[] | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  /** @format date-time */
  nextPaymentAttempt?: string | null;
  number?: string | null;
  onBehalfOfId?: string | null;
  onBehalfOf?: Account | null;
  paid: boolean;
  paidOutOfBand: boolean;
  paymentIntentId?: string | null;
  paymentIntent?: PaymentIntent | null;
  paymentSettings?: InvoicePaymentSettings | null;
  /** @format date-time */
  periodEnd: string;
  /** @format date-time */
  periodStart: string;
  /** @format int64 */
  postPaymentCreditNotesAmount: number;
  /** @format int64 */
  prePaymentCreditNotesAmount: number;
  quoteId?: string | null;
  quote?: Quote | null;
  receiptNumber?: string | null;
  rendering?: InvoiceRendering | null;
  shippingCost?: InvoiceShippingCost | null;
  shippingDetails?: InvoiceShippingDetails | null;
  /** @format int64 */
  startingBalance: number;
  statementDescriptor?: string | null;
  status?: string | null;
  statusTransitions?: InvoiceStatusTransitions | null;
  subscriptionId?: string | null;
  subscription?: Subscription | null;
  subscriptionDetails?: InvoiceSubscriptionDetails | null;
  /** @format date-time */
  subscriptionProrationDate: string;
  /** @format int64 */
  subtotal: number;
  /** @format int64 */
  subtotalExcludingTax?: number | null;
  /** @format int64 */
  tax?: number | null;
  testClockId?: string | null;
  testClock?: TestClock | null;
  thresholdReason?: InvoiceThresholdReason | null;
  /** @format int64 */
  total: number;
  totalDiscountAmounts?: InvoiceDiscountAmount[] | null;
  /** @format int64 */
  totalExcludingTax?: number | null;
  totalTaxAmounts?: InvoiceTaxAmount[] | null;
  transferData?: InvoiceTransferData | null;
  /** @format date-time */
  webhooksDeliveredAt?: string | null;
}

export interface InvoiceAutomaticTax {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  enabled: boolean;
  liability?: InvoiceAutomaticTaxLiability | null;
  status?: string | null;
}

export interface InvoiceAutomaticTaxLiability {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountId?: string | null;
  account?: Account | null;
  type?: string | null;
}

export interface InvoiceCustomField {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  name?: string | null;
  value?: string | null;
}

export interface InvoiceCustomerTaxId {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  type?: string | null;
  value?: string | null;
}

export interface InvoiceDiscountAmount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  discountId?: string | null;
  discount?: Discount | null;
}

export interface InvoiceFromInvoice {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  action?: string | null;
  invoiceId?: string | null;
  invoice?: Invoice | null;
}

export interface InvoiceIssuer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountId?: string | null;
  account?: Account | null;
  type?: string | null;
}

export interface InvoiceItem {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format int64 */
  amount: number;
  currency?: string | null;
  customerId?: string | null;
  customer?: Customer | null;
  /** @format date-time */
  date: string;
  deleted?: boolean | null;
  description?: string | null;
  discountable: boolean;
  discountIds?: string[] | null;
  discounts?: Discount[] | null;
  invoiceId?: string | null;
  invoice?: Invoice | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  period?: InvoiceItemPeriod | null;
  plan?: Plan | null;
  price?: Price | null;
  proration: boolean;
  /** @format int64 */
  quantity: number;
  subscriptionId?: string | null;
  subscription?: Subscription | null;
  subscriptionItem?: string | null;
  taxRates?: TaxRate[] | null;
  testClockId?: string | null;
  testClock?: TestClock | null;
  /** @format int64 */
  unitAmount?: number | null;
  /** @format double */
  unitAmountDecimal?: number | null;
}

export interface InvoiceItemPeriod {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  end: string;
  /** @format date-time */
  start: string;
}

export interface InvoiceLineItem {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format int64 */
  amount: number;
  /** @format int64 */
  amountExcludingTax?: number | null;
  currency?: string | null;
  description?: string | null;
  discountAmounts?: InvoiceLineItemDiscountAmount[] | null;
  discountable: boolean;
  discountIds?: string[] | null;
  discounts?: Discount[] | null;
  invoice?: string | null;
  invoiceItemId?: string | null;
  invoiceItem?: InvoiceItem | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  period?: InvoiceLineItemPeriod | null;
  plan?: Plan | null;
  price?: Price | null;
  proration: boolean;
  prorationDetails?: InvoiceLineItemProrationDetails | null;
  /** @format int64 */
  quantity?: number | null;
  subscriptionId?: string | null;
  subscription?: Subscription | null;
  subscriptionItemId?: string | null;
  subscriptionItem?: SubscriptionItem | null;
  taxAmounts?: InvoiceLineItemTaxAmount[] | null;
  taxRates?: TaxRate[] | null;
  type?: string | null;
  /** @format double */
  unitAmountExcludingTax?: number | null;
}

export interface InvoiceLineItemDiscountAmount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  discountId?: string | null;
  discount?: Discount | null;
}

export interface InvoiceLineItemPeriod {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  end: string;
  /** @format date-time */
  start: string;
}

export interface InvoiceLineItemProrationDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  creditedItems?: InvoiceLineItemProrationDetailsCreditedItems | null;
}

export interface InvoiceLineItemProrationDetailsCreditedItems {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  invoice?: string | null;
  invoiceLineItems?: string[] | null;
}

export interface InvoiceLineItemTaxAmount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  inclusive: boolean;
  taxRateId?: string | null;
  taxRate?: TaxRate | null;
  taxabilityReason?: string | null;
  /** @format int64 */
  taxableAmount?: number | null;
}

export interface InvoicePaymentSettings {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  defaultMandate?: string | null;
  paymentMethodOptions?: InvoicePaymentSettingsPaymentMethodOptions | null;
  paymentMethodTypes?: string[] | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  acssDebit?: InvoicePaymentSettingsPaymentMethodOptionsAcssDebit | null;
  bancontact?: InvoicePaymentSettingsPaymentMethodOptionsBancontact | null;
  card?: InvoicePaymentSettingsPaymentMethodOptionsCard | null;
  customerBalance?: InvoicePaymentSettingsPaymentMethodOptionsCustomerBalance | null;
  konbini?: InvoicePaymentSettingsPaymentMethodOptionsKonbini | null;
  sepaDebit?: InvoicePaymentSettingsPaymentMethodOptionsSepaDebit | null;
  usBankAccount?: InvoicePaymentSettingsPaymentMethodOptionsUsBankAccount | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsAcssDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  mandateOptions?: InvoicePaymentSettingsPaymentMethodOptionsAcssDebitMandateOptions | null;
  verificationMethod?: string | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsAcssDebitMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  transactionType?: string | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsBancontact {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  preferredLanguage?: string | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsCard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  installments?: InvoicePaymentSettingsPaymentMethodOptionsCardInstallments | null;
  requestThreeDSecure?: string | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsCardInstallments {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  enabled?: boolean | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsCustomerBalance {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankTransfer?: InvoicePaymentSettingsPaymentMethodOptionsCustomerBalanceBankTransfer | null;
  fundingType?: string | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsCustomerBalanceBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  euBankTransfer?: InvoicePaymentSettingsPaymentMethodOptionsCustomerBalanceBankTransferEuBankTransfer | null;
  type?: string | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsCustomerBalanceBankTransferEuBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  country?: string | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsKonbini {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsSepaDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsUsBankAccount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  financialConnections?: InvoicePaymentSettingsPaymentMethodOptionsUsBankAccountFinancialConnections | null;
  verificationMethod?: string | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsUsBankAccountFinancialConnections {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  filters?: InvoicePaymentSettingsPaymentMethodOptionsUsBankAccountFinancialConnectionsFilters | null;
  permissions?: string[] | null;
  prefetch?: string[] | null;
}

export interface InvoicePaymentSettingsPaymentMethodOptionsUsBankAccountFinancialConnectionsFilters {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountSubcategories?: string[] | null;
}

export interface InvoiceRendering {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  amountTaxDisplay?: string | null;
  pdf?: InvoiceRenderingPdf | null;
}

export interface InvoiceRenderingPdf {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  pageSize?: string | null;
}

export interface InvoiceShippingCost {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountSubtotal: number;
  /** @format int64 */
  amountTax: number;
  /** @format int64 */
  amountTotal: number;
  shippingRateId?: string | null;
  shippingRate?: ShippingRate | null;
  taxes?: InvoiceShippingCostTax[] | null;
}

export interface InvoiceShippingCostTax {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  rate?: TaxRate | null;
  taxabilityReason?: string | null;
  /** @format int64 */
  taxableAmount?: number | null;
}

export interface InvoiceShippingDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  address?: Address | null;
  carrier?: string | null;
  name?: string | null;
  phone?: string | null;
  trackingNumber?: string | null;
}

export interface InvoiceStatusTransitions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  finalizedAt?: string | null;
  /** @format date-time */
  markedUncollectibleAt?: string | null;
  /** @format date-time */
  paidAt?: string | null;
  /** @format date-time */
  voidedAt?: string | null;
}

export interface InvoiceSubscriptionDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  metadata?: Record<string, string | null>;
}

export interface InvoiceTaxAmount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  inclusive: boolean;
  taxRateId?: string | null;
  taxRate?: TaxRate | null;
  taxabilityReason?: string | null;
  /** @format int64 */
  taxableAmount?: number | null;
}

export interface InvoiceThresholdReason {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountGte?: number | null;
  itemReasons?: InvoiceThresholdReasonItemReason[] | null;
}

export interface InvoiceThresholdReasonItemReason {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  lineItemIds?: string[] | null;
  /** @format int64 */
  usageGte: number;
}

export interface InvoiceTransferData {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount?: number | null;
  destinationId?: string | null;
  destination?: Account | null;
}

export type JToken = JToken[];

export interface LineItem {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format int64 */
  amountDiscount: number;
  /** @format int64 */
  amountSubtotal: number;
  /** @format int64 */
  amountTax: number;
  /** @format int64 */
  amountTotal: number;
  currency?: string | null;
  deleted?: boolean | null;
  description?: string | null;
  discounts?: LineItemDiscount[] | null;
  price?: Price | null;
  /** @format int64 */
  quantity?: number | null;
  taxes?: LineItemTax[] | null;
}

export interface LineItemDiscount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  discount?: Discount | null;
}

export interface LineItemTax {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  rate?: TaxRate | null;
  taxabilityReason?: string | null;
  /** @format int64 */
  taxableAmount?: number | null;
}

export interface Mandate {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  customerAcceptance?: MandateCustomerAcceptance | null;
  livemode: boolean;
  multiUse?: MandateMultiUse | null;
  onBehalfOf?: string | null;
  paymentMethodId?: string | null;
  paymentMethod?: PaymentMethod | null;
  paymentMethodDetails?: MandatePaymentMethodDetails | null;
  singleUse?: MandateSingleUse | null;
  status?: string | null;
  type?: string | null;
}

export interface MandateCustomerAcceptance {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  acceptedAt?: string | null;
  offline?: MandateCustomerAcceptanceOffline | null;
  online?: MandateCustomerAcceptanceOnline | null;
  type?: string | null;
}

export interface MandateCustomerAcceptanceOffline {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface MandateCustomerAcceptanceOnline {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  ipAddress?: string | null;
  userAgent?: string | null;
}

export interface MandateMultiUse {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface MandatePaymentMethodDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  acssDebit?: MandatePaymentMethodDetailsAcssDebit | null;
  amazonPay?: MandatePaymentMethodDetailsAmazonPay | null;
  auBecsDebit?: MandatePaymentMethodDetailsAuBecsDebit | null;
  bacsDebit?: MandatePaymentMethodDetailsBacsDebit | null;
  card?: MandatePaymentMethodDetailsCard | null;
  cashapp?: MandatePaymentMethodDetailsCashapp | null;
  link?: MandatePaymentMethodDetailsLink | null;
  paypal?: MandatePaymentMethodDetailsPaypal | null;
  revolutPay?: MandatePaymentMethodDetailsRevolutPay | null;
  sepaDebit?: MandatePaymentMethodDetailsSepaDebit | null;
  type?: string | null;
  usBankAccount?: MandatePaymentMethodDetailsUsBankAccount | null;
}

export interface MandatePaymentMethodDetailsAcssDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  defaultFor?: string[] | null;
  intervalDescription?: string | null;
  paymentSchedule?: string | null;
  transactionType?: string | null;
}

export interface MandatePaymentMethodDetailsAmazonPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface MandatePaymentMethodDetailsAuBecsDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  url?: string | null;
}

export interface MandatePaymentMethodDetailsBacsDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  networkStatus?: string | null;
  reference?: string | null;
  revocationReason?: string | null;
  url?: string | null;
}

export interface MandatePaymentMethodDetailsCard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface MandatePaymentMethodDetailsCashapp {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface MandatePaymentMethodDetailsLink {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface MandatePaymentMethodDetailsPaypal {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  billingAgreementId?: string | null;
  payerId?: string | null;
}

export interface MandatePaymentMethodDetailsRevolutPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface MandatePaymentMethodDetailsSepaDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  url?: string | null;
}

export interface MandatePaymentMethodDetailsUsBankAccount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  collectionMethod?: string | null;
}

export interface MandateSingleUse {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  currency?: string | null;
}

export interface NoteRequest {
  /** @format uuid */
  userId: string;
  title: string;
  content: string;
}

export interface PaginatedSignalsGroupResponse {
  hasNextPage: boolean;
  signalsGroups: SignalsGroupResponse[];
}

export interface PaymentIntent {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format int64 */
  amount: number;
  /** @format int64 */
  amountCapturable: number;
  amountDetails?: PaymentIntentAmountDetails | null;
  /** @format int64 */
  amountReceived: number;
  applicationId?: string | null;
  application?: Application | null;
  /** @format int64 */
  applicationFeeAmount?: number | null;
  automaticPaymentMethods?: PaymentIntentAutomaticPaymentMethods | null;
  /** @format date-time */
  canceledAt?: string | null;
  cancellationReason?: string | null;
  captureMethod?: string | null;
  clientSecret?: string | null;
  confirmationMethod?: string | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  customerId?: string | null;
  customer?: Customer | null;
  description?: string | null;
  invoiceId?: string | null;
  invoice?: Invoice | null;
  lastPaymentError?: StripeError | null;
  latestChargeId?: string | null;
  latestCharge?: Charge | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  nextAction?: PaymentIntentNextAction | null;
  onBehalfOfId?: string | null;
  onBehalfOf?: Account | null;
  paymentMethodId?: string | null;
  paymentMethod?: PaymentMethod | null;
  paymentMethodConfigurationDetails?: PaymentIntentPaymentMethodConfigurationDetails | null;
  paymentMethodOptions?: PaymentIntentPaymentMethodOptions | null;
  paymentMethodTypes?: string[] | null;
  processing?: PaymentIntentProcessing | null;
  receiptEmail?: string | null;
  reviewId?: string | null;
  review?: Review | null;
  setupFutureUsage?: string | null;
  shipping?: Shipping | null;
  sourceId?: string | null;
  source?: IPaymentSource | null;
  statementDescriptor?: string | null;
  statementDescriptorSuffix?: string | null;
  status?: string | null;
  transferData?: PaymentIntentTransferData | null;
  transferGroup?: string | null;
}

export interface PaymentIntentAmountDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  tip?: PaymentIntentAmountDetailsTip | null;
}

export interface PaymentIntentAmountDetailsTip {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
}

export interface PaymentIntentAutomaticPaymentMethods {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  allowRedirects?: string | null;
  enabled: boolean;
}

export interface PaymentIntentNextAction {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  alipayHandleRedirect?: PaymentIntentNextActionAlipayHandleRedirect | null;
  boletoDisplayDetails?: PaymentIntentNextActionBoletoDisplayDetails | null;
  cardAwaitNotification?: PaymentIntentNextActionCardAwaitNotification | null;
  cashappHandleRedirectOrDisplayQrCode?: PaymentIntentNextActionCashappHandleRedirectOrDisplayQrCode | null;
  displayBankTransferInstructions?: PaymentIntentNextActionDisplayBankTransferInstructions | null;
  konbiniDisplayDetails?: PaymentIntentNextActionKonbiniDisplayDetails | null;
  multibancoDisplayDetails?: PaymentIntentNextActionMultibancoDisplayDetails | null;
  oxxoDisplayDetails?: PaymentIntentNextActionOxxoDisplayDetails | null;
  paynowDisplayQrCode?: PaymentIntentNextActionPaynowDisplayQrCode | null;
  pixDisplayQrCode?: PaymentIntentNextActionPixDisplayQrCode | null;
  promptpayDisplayQrCode?: PaymentIntentNextActionPromptpayDisplayQrCode | null;
  redirectToUrl?: PaymentIntentNextActionRedirectToUrl | null;
  swishHandleRedirectOrDisplayQrCode?: PaymentIntentNextActionSwishHandleRedirectOrDisplayQrCode | null;
  type?: string | null;
  useStripeSdk?: PaymentIntentNextActionUseStripeSdk | null;
  verifyWithMicrodeposits?: PaymentIntentNextActionVerifyWithMicrodeposits | null;
  wechatPayDisplayQrCode?: PaymentIntentNextActionWechatPayDisplayQrCode | null;
  wechatPayRedirectToAndroidApp?: PaymentIntentNextActionWechatPayRedirectToAndroidApp | null;
  wechatPayRedirectToIosApp?: PaymentIntentNextActionWechatPayRedirectToIosApp | null;
}

export interface PaymentIntentNextActionAlipayHandleRedirect {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  nativeData?: string | null;
  nativeUrl?: string | null;
  returnUrl?: string | null;
  url?: string | null;
}

export interface PaymentIntentNextActionBoletoDisplayDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  expiresAt?: string | null;
  hostedVoucherUrl?: string | null;
  number?: string | null;
  pdf?: string | null;
}

export interface PaymentIntentNextActionCardAwaitNotification {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  chargeAttemptAt?: string | null;
  customerApprovalRequired?: boolean | null;
}

export interface PaymentIntentNextActionCashappHandleRedirectOrDisplayQrCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  hostedInstructionsUrl?: string | null;
  mobileAuthUrl?: string | null;
  qrCode?: PaymentIntentNextActionCashappHandleRedirectOrDisplayQrCodeQrCode | null;
}

export interface PaymentIntentNextActionCashappHandleRedirectOrDisplayQrCodeQrCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  expiresAt: string;
  imageUrlPng?: string | null;
  imageUrlSvg?: string | null;
}

export interface PaymentIntentNextActionDisplayBankTransferInstructions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountRemaining?: number | null;
  currency?: string | null;
  financialAddresses?: PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddress[] | null;
  hostedInstructionsUrl?: string | null;
  reference?: string | null;
  type?: string | null;
}

export interface PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddress {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  aba?: PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddressAba | null;
  iban?: PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddressIban | null;
  sortCode?: PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddressSortCode | null;
  spei?: PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddressSpei | null;
  supportedNetworks?: string[] | null;
  swift?: PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddressSwift | null;
  type?: string | null;
  zengin?: PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddressZengin | null;
}

export interface PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddressAba {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountNumber?: string | null;
  bankName?: string | null;
  routingNumber?: string | null;
}

export interface PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddressIban {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountHolderName?: string | null;
  bic?: string | null;
  country?: string | null;
  iban?: string | null;
}

export interface PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddressSortCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountHolderName?: string | null;
  accountNumber?: string | null;
  sortCode?: string | null;
}

export interface PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddressSpei {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankCode?: string | null;
  bankName?: string | null;
  clabe?: string | null;
}

export interface PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddressSwift {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountNumber?: string | null;
  bankName?: string | null;
  swiftCode?: string | null;
}

export interface PaymentIntentNextActionDisplayBankTransferInstructionsFinancialAddressZengin {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountHolderName?: string | null;
  accountNumber?: string | null;
  accountType?: string | null;
  bankCode?: string | null;
  bankName?: string | null;
  branchCode?: string | null;
  branchName?: string | null;
}

export interface PaymentIntentNextActionKonbiniDisplayDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  expiresAt: string;
  hostedVoucherUrl?: string | null;
  stores?: PaymentIntentNextActionKonbiniDisplayDetailsStores | null;
}

export interface PaymentIntentNextActionKonbiniDisplayDetailsStores {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  familymart?: PaymentIntentNextActionKonbiniDisplayDetailsStoresFamilymart | null;
  lawson?: PaymentIntentNextActionKonbiniDisplayDetailsStoresLawson | null;
  ministop?: PaymentIntentNextActionKonbiniDisplayDetailsStoresMinistop | null;
  seicomart?: PaymentIntentNextActionKonbiniDisplayDetailsStoresSeicomart | null;
}

export interface PaymentIntentNextActionKonbiniDisplayDetailsStoresFamilymart {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  confirmationNumber?: string | null;
  paymentCode?: string | null;
}

export interface PaymentIntentNextActionKonbiniDisplayDetailsStoresLawson {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  confirmationNumber?: string | null;
  paymentCode?: string | null;
}

export interface PaymentIntentNextActionKonbiniDisplayDetailsStoresMinistop {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  confirmationNumber?: string | null;
  paymentCode?: string | null;
}

export interface PaymentIntentNextActionKonbiniDisplayDetailsStoresSeicomart {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  confirmationNumber?: string | null;
  paymentCode?: string | null;
}

export interface PaymentIntentNextActionMultibancoDisplayDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  entity?: string | null;
  /** @format date-time */
  expiresAt?: string | null;
  hostedVoucherUrl?: string | null;
  reference?: string | null;
}

export interface PaymentIntentNextActionOxxoDisplayDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  expiresAfter?: string | null;
  hostedVoucherUrl?: string | null;
  number?: string | null;
}

export interface PaymentIntentNextActionPaynowDisplayQrCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  data?: string | null;
  hostedInstructionsUrl?: string | null;
  imageUrlPng?: string | null;
  imageUrlSvg?: string | null;
}

export interface PaymentIntentNextActionPixDisplayQrCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  data?: string | null;
  /** @format date-time */
  expiresAt: string;
  hostedInstructionsUrl?: string | null;
  imageUrlPng?: string | null;
  imageUrlSvg?: string | null;
}

export interface PaymentIntentNextActionPromptpayDisplayQrCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  data?: string | null;
  hostedInstructionsUrl?: string | null;
  imageUrlPng?: string | null;
  imageUrlSvg?: string | null;
}

export interface PaymentIntentNextActionRedirectToUrl {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  returnUrl?: string | null;
  url?: string | null;
}

export interface PaymentIntentNextActionSwishHandleRedirectOrDisplayQrCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  hostedInstructionsUrl?: string | null;
  mobileAuthUrl?: string | null;
  qrCode?: PaymentIntentNextActionSwishHandleRedirectOrDisplayQrCodeQrCode | null;
}

export interface PaymentIntentNextActionSwishHandleRedirectOrDisplayQrCodeQrCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  data?: string | null;
  imageUrlPng?: string | null;
  imageUrlSvg?: string | null;
}

export interface PaymentIntentNextActionUseStripeSdk {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentIntentNextActionVerifyWithMicrodeposits {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  arrivalDate: string;
  hostedVerificationUrl?: string | null;
  microdepositType?: string | null;
}

export interface PaymentIntentNextActionWechatPayDisplayQrCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  data?: string | null;
  hostedInstructionsUrl?: string | null;
  imageDataUrl?: string | null;
  imageUrlPng?: string | null;
  imageUrlSvg?: string | null;
}

export interface PaymentIntentNextActionWechatPayRedirectToAndroidApp {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  appId?: string | null;
  nonceStr?: string | null;
  package?: string | null;
  partnerId?: string | null;
  prepayId?: string | null;
  sign?: string | null;
  timestamp?: string | null;
}

export interface PaymentIntentNextActionWechatPayRedirectToIosApp {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  nativeUrl?: string | null;
}

export interface PaymentIntentPaymentMethodConfigurationDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  parent?: string | null;
}

export interface PaymentIntentPaymentMethodOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  acssDebit?: PaymentIntentPaymentMethodOptionsAcssDebit | null;
  affirm?: PaymentIntentPaymentMethodOptionsAffirm | null;
  afterpayClearpay?: PaymentIntentPaymentMethodOptionsAfterpayClearpay | null;
  alipay?: PaymentIntentPaymentMethodOptionsAlipay | null;
  amazonPay?: PaymentIntentPaymentMethodOptionsAmazonPay | null;
  auBecsDebit?: PaymentIntentPaymentMethodOptionsAuBecsDebit | null;
  bacsDebit?: PaymentIntentPaymentMethodOptionsBacsDebit | null;
  bancontact?: PaymentIntentPaymentMethodOptionsBancontact | null;
  blik?: PaymentIntentPaymentMethodOptionsBlik | null;
  boleto?: PaymentIntentPaymentMethodOptionsBoleto | null;
  card?: PaymentIntentPaymentMethodOptionsCard | null;
  cardPresent?: PaymentIntentPaymentMethodOptionsCardPresent | null;
  cashapp?: PaymentIntentPaymentMethodOptionsCashapp | null;
  customerBalance?: PaymentIntentPaymentMethodOptionsCustomerBalance | null;
  eps?: PaymentIntentPaymentMethodOptionsEps | null;
  fpx?: PaymentIntentPaymentMethodOptionsFpx | null;
  giropay?: PaymentIntentPaymentMethodOptionsGiropay | null;
  grabpay?: PaymentIntentPaymentMethodOptionsGrabpay | null;
  ideal?: PaymentIntentPaymentMethodOptionsIdeal | null;
  interacPresent?: PaymentIntentPaymentMethodOptionsInteracPresent | null;
  klarna?: PaymentIntentPaymentMethodOptionsKlarna | null;
  konbini?: PaymentIntentPaymentMethodOptionsKonbini | null;
  link?: PaymentIntentPaymentMethodOptionsLink | null;
  mobilepay?: PaymentIntentPaymentMethodOptionsMobilepay | null;
  multibanco?: PaymentIntentPaymentMethodOptionsMultibanco | null;
  oxxo?: PaymentIntentPaymentMethodOptionsOxxo | null;
  p24?: PaymentIntentPaymentMethodOptionsP24 | null;
  paynow?: PaymentIntentPaymentMethodOptionsPaynow | null;
  paypal?: PaymentIntentPaymentMethodOptionsPaypal | null;
  pix?: PaymentIntentPaymentMethodOptionsPix | null;
  promptpay?: PaymentIntentPaymentMethodOptionsPromptpay | null;
  revolutPay?: PaymentIntentPaymentMethodOptionsRevolutPay | null;
  sepaDebit?: PaymentIntentPaymentMethodOptionsSepaDebit | null;
  sofort?: PaymentIntentPaymentMethodOptionsSofort | null;
  swish?: PaymentIntentPaymentMethodOptionsSwish | null;
  twint?: PaymentIntentPaymentMethodOptionsTwint | null;
  usBankAccount?: PaymentIntentPaymentMethodOptionsUsBankAccount | null;
  wechatPay?: PaymentIntentPaymentMethodOptionsWechatPay | null;
  zip?: PaymentIntentPaymentMethodOptionsZip | null;
}

export interface PaymentIntentPaymentMethodOptionsAcssDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  mandateOptions?: PaymentIntentPaymentMethodOptionsAcssDebitMandateOptions | null;
  setupFutureUsage?: string | null;
  verificationMethod?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsAcssDebitMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  customMandateUrl?: string | null;
  intervalDescription?: string | null;
  paymentSchedule?: string | null;
  transactionType?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsAffirm {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  captureMethod?: string | null;
  preferredLocale?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsAfterpayClearpay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  captureMethod?: string | null;
  reference?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsAlipay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsAmazonPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  captureMethod?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsAuBecsDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsBacsDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  mandateOptions?: PaymentIntentPaymentMethodOptionsBacsDebitMandateOptions | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsBacsDebitMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentIntentPaymentMethodOptionsBancontact {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  preferredLanguage?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsBlik {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsBoleto {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  expiresAfterDays: number;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsCard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  captureMethod?: string | null;
  installments?: PaymentIntentPaymentMethodOptionsCardInstallments | null;
  mandateOptions?: PaymentIntentPaymentMethodOptionsCardMandateOptions | null;
  network?: string | null;
  requestExtendedAuthorization?: string | null;
  requestIncrementalAuthorization?: string | null;
  requestMulticapture?: string | null;
  requestOvercapture?: string | null;
  requestThreeDSecure?: string | null;
  requireCvcRecollection: boolean;
  setupFutureUsage?: string | null;
  statementDescriptorSuffixKana?: string | null;
  statementDescriptorSuffixKanji?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsCardInstallments {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  availablePlans?: PaymentIntentPaymentMethodOptionsCardInstallmentsPlan[] | null;
  enabled: boolean;
  plan?: PaymentIntentPaymentMethodOptionsCardInstallmentsPlan | null;
}

export interface PaymentIntentPaymentMethodOptionsCardInstallmentsPlan {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  count?: number | null;
  interval?: string | null;
  type?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsCardMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  amountType?: string | null;
  description?: string | null;
  /** @format date-time */
  endDate?: string | null;
  interval?: string | null;
  /** @format int64 */
  intervalCount?: number | null;
  reference?: string | null;
  /** @format date-time */
  startDate: string;
  supportedTypes?: string[] | null;
}

export interface PaymentIntentPaymentMethodOptionsCardPresent {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  requestExtendedAuthorization?: boolean | null;
  requestIncrementalAuthorizationSupport?: boolean | null;
  routing?: PaymentIntentPaymentMethodOptionsCardPresentRouting | null;
}

export interface PaymentIntentPaymentMethodOptionsCardPresentRouting {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  requestedPriority?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsCashapp {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  captureMethod?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsCustomerBalance {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankTransfer?: PaymentIntentPaymentMethodOptionsCustomerBalanceBankTransfer | null;
  fundingType?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsCustomerBalanceBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  euBankTransfer?: PaymentIntentPaymentMethodOptionsCustomerBalanceBankTransferEuBankTransfer | null;
  requestedAddressTypes?: string[] | null;
  type?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsCustomerBalanceBankTransferEuBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  country?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsEps {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsFpx {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsGiropay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsGrabpay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsIdeal {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsInteracPresent {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentIntentPaymentMethodOptionsKlarna {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  captureMethod?: string | null;
  preferredLocale?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsKonbini {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  confirmationNumber?: string | null;
  /** @format int64 */
  expiresAfterDays?: number | null;
  /** @format date-time */
  expiresAt?: string | null;
  productDescription?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsLink {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  captureMethod?: string | null;
  /** @deprecated */
  persistentToken?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsMobilepay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  captureMethod?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsMultibanco {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsOxxo {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  expiresAfterDays: number;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsP24 {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsPaynow {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsPaypal {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  captureMethod?: string | null;
  preferredLocale?: string | null;
  reference?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsPix {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  expiresAfterSeconds?: number | null;
  /** @format date-time */
  expiresAt?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsPromptpay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsRevolutPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  captureMethod?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsSepaDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  mandateOptions?: PaymentIntentPaymentMethodOptionsSepaDebitMandateOptions | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsSepaDebitMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentIntentPaymentMethodOptionsSofort {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  preferredLanguage?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsSwish {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsTwint {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsUsBankAccount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  financialConnections?: PaymentIntentPaymentMethodOptionsUsBankAccountFinancialConnections | null;
  mandateOptions?: PaymentIntentPaymentMethodOptionsUsBankAccountMandateOptions | null;
  preferredSettlementSpeed?: string | null;
  setupFutureUsage?: string | null;
  verificationMethod?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsUsBankAccountFinancialConnections {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  filters?: PaymentIntentPaymentMethodOptionsUsBankAccountFinancialConnectionsFilters | null;
  permissions?: string[] | null;
  prefetch?: string[] | null;
  returnUrl?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsUsBankAccountFinancialConnectionsFilters {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountSubcategories?: string[] | null;
}

export interface PaymentIntentPaymentMethodOptionsUsBankAccountMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  collectionMethod?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsWechatPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  appId?: string | null;
  client?: string | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentPaymentMethodOptionsZip {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  setupFutureUsage?: string | null;
}

export interface PaymentIntentProcessing {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  card?: PaymentIntentProcessingCard | null;
  type?: string | null;
}

export interface PaymentIntentProcessingCard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  customerNotification?: PaymentIntentProcessingCardCustomerNotification | null;
}

export interface PaymentIntentProcessingCardCustomerNotification {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  approvalRequested?: boolean | null;
  /** @format date-time */
  completesAt?: string | null;
}

export interface PaymentIntentTransferData {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  destinationId?: string | null;
  destination?: Account | null;
}

export interface PaymentMethod {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  acssDebit?: PaymentMethodAcssDebit | null;
  affirm?: PaymentMethodAffirm | null;
  afterpayClearpay?: PaymentMethodAfterpayClearpay | null;
  alipay?: PaymentMethodAlipay | null;
  allowRedisplay?: string | null;
  amazonPay?: PaymentMethodAmazonPay | null;
  auBecsDebit?: PaymentMethodAuBecsDebit | null;
  bacsDebit?: PaymentMethodBacsDebit | null;
  bancontact?: PaymentMethodBancontact | null;
  billingDetails?: PaymentMethodBillingDetails | null;
  blik?: PaymentMethodBlik | null;
  boleto?: PaymentMethodBoleto | null;
  card?: PaymentMethodCard | null;
  cardPresent?: PaymentMethodCardPresent | null;
  cashapp?: PaymentMethodCashapp | null;
  /** @format date-time */
  created: string;
  customerId?: string | null;
  customer?: Customer | null;
  customerBalance?: PaymentMethodCustomerBalance | null;
  eps?: PaymentMethodEps | null;
  fpx?: PaymentMethodFpx | null;
  giropay?: PaymentMethodGiropay | null;
  grabpay?: PaymentMethodGrabpay | null;
  ideal?: PaymentMethodIdeal | null;
  interacPresent?: PaymentMethodInteracPresent | null;
  klarna?: PaymentMethodKlarna | null;
  konbini?: PaymentMethodKonbini | null;
  link?: PaymentMethodLink | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  mobilepay?: PaymentMethodMobilepay | null;
  multibanco?: PaymentMethodMultibanco | null;
  oxxo?: PaymentMethodOxxo | null;
  p24?: PaymentMethodP24 | null;
  paynow?: PaymentMethodPaynow | null;
  paypal?: PaymentMethodPaypal | null;
  pix?: PaymentMethodPix | null;
  promptpay?: PaymentMethodPromptpay | null;
  radarOptions?: PaymentMethodRadarOptions | null;
  revolutPay?: PaymentMethodRevolutPay | null;
  sepaDebit?: PaymentMethodSepaDebit | null;
  sofort?: PaymentMethodSofort | null;
  swish?: PaymentMethodSwish | null;
  twint?: PaymentMethodTwint | null;
  type?: string | null;
  usBankAccount?: PaymentMethodUsBankAccount | null;
  wechatPay?: PaymentMethodWechatPay | null;
  zip?: PaymentMethodZip | null;
}

export interface PaymentMethodAcssDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankName?: string | null;
  fingerprint?: string | null;
  institutionNumber?: string | null;
  last4?: string | null;
  transitNumber?: string | null;
}

export interface PaymentMethodAffirm {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodAfterpayClearpay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodAlipay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodAmazonPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodAuBecsDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bsbNumber?: string | null;
  fingerprint?: string | null;
  last4?: string | null;
}

export interface PaymentMethodBacsDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  fingerprint?: string | null;
  last4?: string | null;
  sortCode?: string | null;
}

export interface PaymentMethodBancontact {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodBillingDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  address?: Address | null;
  email?: string | null;
  name?: string | null;
  phone?: string | null;
}

export interface PaymentMethodBlik {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodBoleto {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  taxId?: string | null;
}

export interface PaymentMethodCard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  brand?: string | null;
  checks?: PaymentMethodCardChecks | null;
  country?: string | null;
  description?: string | null;
  displayBrand?: string | null;
  /** @format int64 */
  expMonth: number;
  /** @format int64 */
  expYear: number;
  fingerprint?: string | null;
  funding?: string | null;
  generatedFrom?: PaymentMethodCardGeneratedFrom | null;
  iin?: string | null;
  issuer?: string | null;
  last4?: string | null;
  networks?: PaymentMethodCardNetworks | null;
  threeDSecureUsage?: PaymentMethodCardThreeDSecureUsage | null;
  wallet?: PaymentMethodCardWallet | null;
}

export interface PaymentMethodCardChecks {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  addressLine1Check?: string | null;
  addressPostalCodeCheck?: string | null;
  cvcCheck?: string | null;
}

export interface PaymentMethodCardGeneratedFrom {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  charge?: string | null;
  paymentMethodDetails?: PaymentMethodCardGeneratedFromPaymentMethodDetails | null;
  setupAttemptId?: string | null;
  setupAttempt?: SetupAttempt | null;
}

export interface PaymentMethodCardGeneratedFromPaymentMethodDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  cardPresent?: PaymentMethodCardGeneratedFromPaymentMethodDetailsCardPresent | null;
  type?: string | null;
}

export interface PaymentMethodCardGeneratedFromPaymentMethodDetailsCardPresent {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountAuthorized?: number | null;
  brand?: string | null;
  brandProduct?: string | null;
  /** @format date-time */
  captureBefore: string;
  cardholderName?: string | null;
  country?: string | null;
  description?: string | null;
  emvAuthData?: string | null;
  /** @format int64 */
  expMonth: number;
  /** @format int64 */
  expYear: number;
  fingerprint?: string | null;
  funding?: string | null;
  generatedCard?: string | null;
  iin?: string | null;
  incrementalAuthorizationSupported: boolean;
  issuer?: string | null;
  last4?: string | null;
  network?: string | null;
  networkTransactionId?: string | null;
  offline?: PaymentMethodCardGeneratedFromPaymentMethodDetailsCardPresentOffline | null;
  overcaptureSupported: boolean;
  preferredLocales?: string[] | null;
  readMethod?: string | null;
  receipt?: PaymentMethodCardGeneratedFromPaymentMethodDetailsCardPresentReceipt | null;
  wallet?: PaymentMethodCardGeneratedFromPaymentMethodDetailsCardPresentWallet | null;
}

export interface PaymentMethodCardGeneratedFromPaymentMethodDetailsCardPresentOffline {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  storedAt?: string | null;
  type?: string | null;
}

export interface PaymentMethodCardGeneratedFromPaymentMethodDetailsCardPresentReceipt {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountType?: string | null;
  applicationCryptogram?: string | null;
  applicationPreferredName?: string | null;
  authorizationCode?: string | null;
  authorizationResponseCode?: string | null;
  cardholderVerificationMethod?: string | null;
  dedicatedFileName?: string | null;
  terminalVerificationResults?: string | null;
  transactionStatusInformation?: string | null;
}

export interface PaymentMethodCardGeneratedFromPaymentMethodDetailsCardPresentWallet {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  type?: string | null;
}

export interface PaymentMethodCardNetworks {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  available?: string[] | null;
  preferred?: string | null;
}

export interface PaymentMethodCardPresent {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  brand?: string | null;
  brandProduct?: string | null;
  cardholderName?: string | null;
  country?: string | null;
  description?: string | null;
  /** @format int64 */
  expMonth: number;
  /** @format int64 */
  expYear: number;
  fingerprint?: string | null;
  funding?: string | null;
  iin?: string | null;
  issuer?: string | null;
  last4?: string | null;
  networks?: PaymentMethodCardPresentNetworks | null;
  offline?: PaymentMethodCardPresentOffline | null;
  preferredLocales?: string[] | null;
  readMethod?: string | null;
  wallet?: PaymentMethodCardPresentWallet | null;
}

export interface PaymentMethodCardPresentNetworks {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  available?: string[] | null;
  preferred?: string | null;
}

export interface PaymentMethodCardPresentOffline {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  storedAt?: string | null;
  type?: string | null;
}

export interface PaymentMethodCardPresentWallet {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  type?: string | null;
}

export interface PaymentMethodCardThreeDSecureUsage {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  supported: boolean;
}

export interface PaymentMethodCardWallet {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  amexExpressCheckout?: PaymentMethodCardWalletAmexExpressCheckout | null;
  applePay?: PaymentMethodCardWalletApplePay | null;
  dynamicLast4?: string | null;
  googlePay?: PaymentMethodCardWalletGooglePay | null;
  link?: PaymentMethodCardWalletLink | null;
  masterpass?: PaymentMethodCardWalletMasterpass | null;
  samsungPay?: PaymentMethodCardWalletSamsungPay | null;
  type?: string | null;
  visaCheckout?: PaymentMethodCardWalletVisaCheckout | null;
}

export interface PaymentMethodCardWalletAmexExpressCheckout {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodCardWalletApplePay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodCardWalletGooglePay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodCardWalletLink {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodCardWalletMasterpass {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  billingAddress?: Address | null;
  email?: string | null;
  name?: string | null;
  shippingAddress?: Address | null;
}

export interface PaymentMethodCardWalletSamsungPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodCardWalletVisaCheckout {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  billingAddress?: Address | null;
  email?: string | null;
  name?: string | null;
  shippingAddress?: Address | null;
}

export interface PaymentMethodCashapp {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  buyerId?: string | null;
  cashtag?: string | null;
}

export interface PaymentMethodCustomerBalance {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodEps {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bank?: string | null;
}

export interface PaymentMethodFpx {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountHolderType?: string | null;
  bank?: string | null;
}

export interface PaymentMethodGiropay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodGrabpay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodIdeal {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bank?: string | null;
  bic?: string | null;
}

export interface PaymentMethodInteracPresent {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  brand?: string | null;
  cardholderName?: string | null;
  country?: string | null;
  description?: string | null;
  /** @format int64 */
  expMonth: number;
  /** @format int64 */
  expYear: number;
  fingerprint?: string | null;
  funding?: string | null;
  iin?: string | null;
  issuer?: string | null;
  last4?: string | null;
  networks?: PaymentMethodInteracPresentNetworks | null;
  preferredLocales?: string[] | null;
  readMethod?: string | null;
}

export interface PaymentMethodInteracPresentNetworks {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  available?: string[] | null;
  preferred?: string | null;
}

export interface PaymentMethodKlarna {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  dob?: PaymentMethodKlarnaDob | null;
}

export interface PaymentMethodKlarnaDob {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  day?: number | null;
  /** @format int64 */
  month?: number | null;
  /** @format int64 */
  year?: number | null;
}

export interface PaymentMethodKonbini {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodLink {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  email?: string | null;
  /** @deprecated */
  persistentToken?: string | null;
}

export interface PaymentMethodMobilepay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodMultibanco {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodOxxo {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodP24 {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bank?: string | null;
}

export interface PaymentMethodPaynow {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodPaypal {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  payerEmail?: string | null;
  payerId?: string | null;
}

export interface PaymentMethodPix {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodPromptpay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodRadarOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  session?: string | null;
}

export interface PaymentMethodResponse {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  brand: string;
  /** @format date-time */
  expirationDate: string;
  /** @minLength 1 */
  last4Digits: string;
  isDefault: boolean;
}

export interface PaymentMethodRevolutPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodSepaDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankCode?: string | null;
  branchCode?: string | null;
  country?: string | null;
  fingerprint?: string | null;
  generatedFrom?: PaymentMethodSepaDebitGeneratedFrom | null;
  last4?: string | null;
}

export interface PaymentMethodSepaDebitGeneratedFrom {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  chargeId?: string | null;
  charge?: Charge | null;
  setupAttemptId?: string | null;
  setupAttempt?: SetupAttempt | null;
}

export interface PaymentMethodSofort {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  country?: string | null;
}

export interface PaymentMethodSwish {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodTwint {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodUsBankAccount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountHolderType?: string | null;
  accountType?: string | null;
  bankName?: string | null;
  financialConnectionsAccount?: string | null;
  fingerprint?: string | null;
  last4?: string | null;
  networks?: PaymentMethodUsBankAccountNetworks | null;
  routingNumber?: string | null;
  statusDetails?: PaymentMethodUsBankAccountStatusDetails | null;
}

export interface PaymentMethodUsBankAccountNetworks {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  preferred?: string | null;
  supported?: string[] | null;
}

export interface PaymentMethodUsBankAccountStatusDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  blocked?: PaymentMethodUsBankAccountStatusDetailsBlocked | null;
}

export interface PaymentMethodUsBankAccountStatusDetailsBlocked {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  networkCode?: string | null;
  reason?: string | null;
}

export interface PaymentMethodWechatPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PaymentMethodZip {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface PeopleResponse {
  /** @minLength 1 */
  peopleId: string;
  /** @minLength 1 */
  companyId: string;
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  fullName: string;
  /** @minLength 1 */
  publicIdentifier: string;
  /** @minLength 1 */
  headline: string;
  /** @format int32 */
  connections: number;
  /** @format int32 */
  followers: number;
  emailRequired: boolean;
  openConnection: boolean;
  urn: string;
  /** @minLength 1 */
  addressCountryOnly: string;
  /** @minLength 1 */
  addressWithCountry: string;
  /** @minLength 1 */
  addressWithoutCountry: string;
  profileAvatar?: string | null;
  about: string;
  /** @format int32 */
  age?: number | null;
  experiences: ExperienceResponse[];
  educations: EducationResponse[];
}

export interface PeopleResponseInfiniteQueryResponse {
  hasNextPage: boolean;
  data: PeopleResponse[];
}

export interface Person {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  account?: string | null;
  additionalTosAcceptances?: PersonAdditionalTosAcceptances | null;
  address?: Address | null;
  addressKana?: AddressJapan | null;
  addressKanji?: AddressJapan | null;
  /** @format date-time */
  created: string;
  deleted?: boolean | null;
  dob?: Dob | null;
  email?: string | null;
  firstName?: string | null;
  firstNameKana?: string | null;
  firstNameKanji?: string | null;
  fullNameAliases?: string[] | null;
  futureRequirements?: PersonFutureRequirements | null;
  gender?: string | null;
  idNumberProvided: boolean;
  idNumberSecondaryProvided: boolean;
  lastName?: string | null;
  lastNameKana?: string | null;
  lastNameKanji?: string | null;
  maidenName?: string | null;
  metadata?: Record<string, string | null>;
  nationality?: string | null;
  phone?: string | null;
  politicalExposure?: string | null;
  registeredAddress?: Address | null;
  relationship?: PersonRelationship | null;
  requirements?: PersonRequirements | null;
  ssnLast4Provided: boolean;
  verification?: PersonVerification | null;
}

export interface PersonAdditionalTosAcceptances {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  account?: PersonAdditionalTosAcceptancesAccount | null;
}

export interface PersonAdditionalTosAcceptancesAccount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  date?: string | null;
  ip?: string | null;
  userAgent?: string | null;
}

export interface PersonFutureRequirements {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  alternatives?: PersonFutureRequirementsAlternative[] | null;
  currentlyDue?: string[] | null;
  errors?: PersonFutureRequirementsError[] | null;
  eventuallyDue?: string[] | null;
  pastDue?: string[] | null;
  pendingVerification?: string[] | null;
}

export interface PersonFutureRequirementsAlternative {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  alternativeFieldsDue?: string[] | null;
  originalFieldsDue?: string[] | null;
}

export interface PersonFutureRequirementsError {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  code?: string | null;
  reason?: string | null;
  requirement?: string | null;
}

export interface PersonRelationship {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  director?: boolean | null;
  executive?: boolean | null;
  legalGuardian?: boolean | null;
  owner?: boolean | null;
  /** @format double */
  percentOwnership?: number | null;
  representative?: boolean | null;
  title?: string | null;
}

export interface PersonRequirements {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  alternatives?: PersonRequirementsAlternative[] | null;
  currentlyDue?: string[] | null;
  errors?: PersonRequirementsError[] | null;
  eventuallyDue?: string[] | null;
  pastDue?: string[] | null;
  pendingVerification?: string[] | null;
}

export interface PersonRequirementsAlternative {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  alternativeFieldsDue?: string[] | null;
  originalFieldsDue?: string[] | null;
}

export interface PersonRequirementsError {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  code?: string | null;
  reason?: string | null;
  requirement?: string | null;
}

export interface PersonVerification {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  additionalDocument?: PersonVerificationAdditionalDocument | null;
  details?: string | null;
  detailsCode?: string | null;
  document?: PersonVerificationDocument | null;
  status?: string | null;
}

export interface PersonVerificationAdditionalDocument {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  backId?: string | null;
  back?: File | null;
  details?: string | null;
  detailsCode?: string | null;
  frontId?: string | null;
  front?: File | null;
}

export interface PersonVerificationDocument {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  backId?: string | null;
  back?: File | null;
  details?: string | null;
  detailsCode?: string | null;
  frontId?: string | null;
  front?: File | null;
}

export interface Plan {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  active: boolean;
  aggregateUsage?: string | null;
  /** @format int64 */
  amount?: number | null;
  /** @format double */
  amountDecimal?: number | null;
  billingScheme?: string | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  deleted?: boolean | null;
  interval?: string | null;
  /** @format int64 */
  intervalCount: number;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  meter?: string | null;
  nickname?: string | null;
  productId?: string | null;
  product?: Product | null;
  tiers?: PlanTier[] | null;
  tiersMode?: string | null;
  transformUsage?: PlanTransformUsage | null;
  /** @format int64 */
  trialPeriodDays?: number | null;
  usageType?: string | null;
}

export interface PlanTier {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  flatAmount?: number | null;
  /** @format double */
  flatAmountDecimal?: number | null;
  /** @format int64 */
  unitAmount?: number | null;
  /** @format double */
  unitAmountDecimal?: number | null;
  /** @format int64 */
  upTo?: number | null;
}

export interface PlanTransformUsage {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  divideBy: number;
  round?: string | null;
}

export interface Price {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  active: boolean;
  billingScheme?: string | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  currencyOptions?: Record<string, PriceCurrencyOptions>;
  customUnitAmount?: PriceCustomUnitAmount | null;
  deleted?: boolean | null;
  livemode: boolean;
  lookupKey?: string | null;
  metadata?: Record<string, string | null>;
  nickname?: string | null;
  productId?: string | null;
  product?: Product | null;
  recurring?: PriceRecurring | null;
  taxBehavior?: string | null;
  tiers?: PriceTier[] | null;
  tiersMode?: string | null;
  transformQuantity?: PriceTransformQuantity | null;
  type?: string | null;
  /** @format int64 */
  unitAmount?: number | null;
  /** @format double */
  unitAmountDecimal?: number | null;
}

export interface PriceCurrencyOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  customUnitAmount?: PriceCurrencyOptionsCustomUnitAmount | null;
  taxBehavior?: string | null;
  tiers?: PriceCurrencyOptionsTier[] | null;
  /** @format int64 */
  unitAmount?: number | null;
  /** @format double */
  unitAmountDecimal?: number | null;
}

export interface PriceCurrencyOptionsCustomUnitAmount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  maximum?: number | null;
  /** @format int64 */
  minimum?: number | null;
  /** @format int64 */
  preset?: number | null;
}

export interface PriceCurrencyOptionsTier {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  flatAmount?: number | null;
  /** @format double */
  flatAmountDecimal?: number | null;
  /** @format int64 */
  unitAmount?: number | null;
  /** @format double */
  unitAmountDecimal?: number | null;
  /** @format int64 */
  upTo?: number | null;
}

export interface PriceCustomUnitAmount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  maximum?: number | null;
  /** @format int64 */
  minimum?: number | null;
  /** @format int64 */
  preset?: number | null;
}

export interface PriceRecurring {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  aggregateUsage?: string | null;
  interval?: string | null;
  /** @format int64 */
  intervalCount: number;
  meter?: string | null;
  /** @format int64 */
  trialPeriodDays?: number | null;
  usageType?: string | null;
}

export interface PriceTier {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  flatAmount?: number | null;
  /** @format double */
  flatAmountDecimal?: number | null;
  /** @format int64 */
  unitAmount?: number | null;
  /** @format double */
  unitAmountDecimal?: number | null;
  /** @format int64 */
  upTo?: number | null;
}

export interface PriceTransformQuantity {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  divideBy: number;
  round?: string | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface Product {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  active: boolean;
  /** @format date-time */
  created: string;
  defaultPriceId?: string | null;
  defaultPrice?: Price | null;
  deleted?: boolean | null;
  description?: string | null;
  images?: string[] | null;
  livemode: boolean;
  marketingFeatures?: ProductMarketingFeature[] | null;
  metadata?: Record<string, string | null>;
  name?: string | null;
  packageDimensions?: ProductPackageDimensions | null;
  shippable?: boolean | null;
  statementDescriptor?: string | null;
  taxCodeId?: string | null;
  taxCode?: TaxCode | null;
  type?: string | null;
  unitLabel?: string | null;
  /** @format date-time */
  updated: string;
  url?: string | null;
}

export interface ProductMarketingFeature {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  name?: string | null;
}

export interface ProductPackageDimensions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format double */
  height: number;
  /** @format double */
  length: number;
  /** @format double */
  weight: number;
  /** @format double */
  width: number;
}

export interface PromotionCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  active: boolean;
  code?: string | null;
  coupon?: Coupon | null;
  /** @format date-time */
  created: string;
  customerId?: string | null;
  customer?: Customer | null;
  /** @format date-time */
  expiresAt?: string | null;
  livemode: boolean;
  /** @format int64 */
  maxRedemptions?: number | null;
  metadata?: Record<string, string | null>;
  restrictions?: PromotionCodeRestrictions | null;
  /** @format int64 */
  timesRedeemed: number;
}

export interface PromotionCodeRestrictions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  currencyOptions?: Record<string, PromotionCodeRestrictionsCurrencyOptions>;
  firstTimeTransaction: boolean;
  /** @format int64 */
  minimumAmount?: number | null;
  minimumAmountCurrency?: string | null;
}

export interface PromotionCodeRestrictionsCurrencyOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  minimumAmount: number;
}

export interface QueryResponse {
  /** @format uuid */
  id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt?: string | null;
  userId: string;
  query: string;
  filter: BaseFilter;
  /** @format int32 */
  countOfCompanies: number;
  title: string;
  signalTypes: SignalType[];
  hasSignals: boolean;
}

export interface Quote {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format int64 */
  amountSubtotal: number;
  /** @format int64 */
  amountTotal: number;
  applicationId?: string | null;
  application?: Application | null;
  /** @format int64 */
  applicationFeeAmount?: number | null;
  /** @format double */
  applicationFeePercent?: number | null;
  automaticTax?: QuoteAutomaticTax | null;
  collectionMethod?: string | null;
  computed?: QuoteComputed | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  customerId?: string | null;
  customer?: Customer | null;
  defaultTaxRateIds?: string[] | null;
  defaultTaxRates?: TaxRate[] | null;
  description?: string | null;
  discountIds?: string[] | null;
  discounts?: Discount[] | null;
  /** @format date-time */
  expiresAt: string;
  footer?: string | null;
  fromQuote?: QuoteFromQuote | null;
  header?: string | null;
  invoiceId?: string | null;
  invoice?: Invoice | null;
  invoiceSettings?: QuoteInvoiceSettings | null;
  lineItems?: LineItem[] | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  number?: string | null;
  onBehalfOfId?: string | null;
  onBehalfOf?: Account | null;
  status?: string | null;
  statusTransitions?: QuoteStatusTransitions | null;
  subscriptionId?: string | null;
  subscription?: Subscription | null;
  subscriptionData?: QuoteSubscriptionData | null;
  subscriptionScheduleId?: string | null;
  subscriptionSchedule?: SubscriptionSchedule | null;
  testClockId?: string | null;
  testClock?: TestClock | null;
  totalDetails?: QuoteTotalDetails | null;
  transferData?: QuoteTransferData | null;
}

export interface QuoteAutomaticTax {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  enabled: boolean;
  liability?: QuoteAutomaticTaxLiability | null;
  status?: string | null;
}

export interface QuoteAutomaticTaxLiability {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountId?: string | null;
  account?: Account | null;
  type?: string | null;
}

export interface QuoteComputed {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  recurring?: QuoteComputedRecurring | null;
  upfront?: QuoteComputedUpfront | null;
}

export interface QuoteComputedRecurring {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountSubtotal: number;
  /** @format int64 */
  amountTotal: number;
  interval?: string | null;
  /** @format int64 */
  intervalCount: number;
  totalDetails?: QuoteComputedRecurringTotalDetails | null;
}

export interface QuoteComputedRecurringTotalDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountDiscount: number;
  /** @format int64 */
  amountShipping?: number | null;
  /** @format int64 */
  amountTax: number;
  breakdown?: QuoteComputedRecurringTotalDetailsBreakdown | null;
}

export interface QuoteComputedRecurringTotalDetailsBreakdown {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  discounts?: QuoteComputedRecurringTotalDetailsBreakdownDiscount[] | null;
  taxes?: QuoteComputedRecurringTotalDetailsBreakdownTax[] | null;
}

export interface QuoteComputedRecurringTotalDetailsBreakdownDiscount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  discount?: Discount | null;
}

export interface QuoteComputedRecurringTotalDetailsBreakdownTax {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  rate?: TaxRate | null;
  taxabilityReason?: string | null;
  /** @format int64 */
  taxableAmount?: number | null;
}

export interface QuoteComputedUpfront {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountSubtotal: number;
  /** @format int64 */
  amountTotal: number;
  lineItems?: LineItem[] | null;
  totalDetails?: QuoteComputedUpfrontTotalDetails | null;
}

export interface QuoteComputedUpfrontTotalDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountDiscount: number;
  /** @format int64 */
  amountShipping?: number | null;
  /** @format int64 */
  amountTax: number;
  breakdown?: QuoteComputedUpfrontTotalDetailsBreakdown | null;
}

export interface QuoteComputedUpfrontTotalDetailsBreakdown {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  discounts?: QuoteComputedUpfrontTotalDetailsBreakdownDiscount[] | null;
  taxes?: QuoteComputedUpfrontTotalDetailsBreakdownTax[] | null;
}

export interface QuoteComputedUpfrontTotalDetailsBreakdownDiscount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  discount?: Discount | null;
}

export interface QuoteComputedUpfrontTotalDetailsBreakdownTax {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  rate?: TaxRate | null;
  taxabilityReason?: string | null;
  /** @format int64 */
  taxableAmount?: number | null;
}

export interface QuoteFromQuote {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  isRevision: boolean;
  quoteId?: string | null;
  quote?: Quote | null;
}

export interface QuoteInvoiceSettings {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  daysUntilDue?: number | null;
  issuer?: QuoteInvoiceSettingsIssuer | null;
}

export interface QuoteInvoiceSettingsIssuer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountId?: string | null;
  account?: Account | null;
  type?: string | null;
}

export interface QuoteStatusTransitions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  acceptedAt?: string | null;
  /** @format date-time */
  canceledAt?: string | null;
  /** @format date-time */
  finalizedAt?: string | null;
}

export interface QuoteSubscriptionData {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  description?: string | null;
  /** @format date-time */
  effectiveDate?: string | null;
  metadata?: Record<string, string | null>;
  /** @format int64 */
  trialPeriodDays?: number | null;
}

export interface QuoteTotalDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountDiscount: number;
  /** @format int64 */
  amountShipping?: number | null;
  /** @format int64 */
  amountTax: number;
  breakdown?: QuoteTotalDetailsBreakdown | null;
}

export interface QuoteTotalDetailsBreakdown {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  discounts?: QuoteTotalDetailsBreakdownDiscount[] | null;
  taxes?: QuoteTotalDetailsBreakdownTax[] | null;
}

export interface QuoteTotalDetailsBreakdownDiscount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  discount?: Discount | null;
}

export interface QuoteTotalDetailsBreakdownTax {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  rate?: TaxRate | null;
  taxabilityReason?: string | null;
  /** @format int64 */
  taxableAmount?: number | null;
}

export interface QuoteTransferData {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount?: number | null;
  /** @format double */
  amountPercent?: number | null;
  destinationId?: string | null;
  destination?: Account | null;
}

export interface RefreshTokenRequest {
  refreshToken: string;
}

export interface Refund {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format int64 */
  amount: number;
  balanceTransactionId?: string | null;
  balanceTransaction?: BalanceTransaction | null;
  chargeId?: string | null;
  charge?: Charge | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  description?: string | null;
  destinationDetails?: RefundDestinationDetails | null;
  failureBalanceTransactionId?: string | null;
  failureBalanceTransaction?: BalanceTransaction | null;
  failureReason?: string | null;
  instructionsEmail?: string | null;
  metadata?: Record<string, string | null>;
  nextAction?: RefundNextAction | null;
  paymentIntentId?: string | null;
  paymentIntent?: PaymentIntent | null;
  reason?: string | null;
  receiptNumber?: string | null;
  sourceTransferReversalId?: string | null;
  sourceTransferReversal?: TransferReversal | null;
  status?: string | null;
  transferReversalId?: string | null;
  transferReversal?: TransferReversal | null;
}

export interface RefundDestinationDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  affirm?: RefundDestinationDetailsAffirm | null;
  afterpayClearpay?: RefundDestinationDetailsAfterpayClearpay | null;
  alipay?: RefundDestinationDetailsAlipay | null;
  amazonPay?: RefundDestinationDetailsAmazonPay | null;
  auBankTransfer?: RefundDestinationDetailsAuBankTransfer | null;
  blik?: RefundDestinationDetailsBlik | null;
  brBankTransfer?: RefundDestinationDetailsBrBankTransfer | null;
  card?: RefundDestinationDetailsCard | null;
  cashapp?: RefundDestinationDetailsCashapp | null;
  customerCashBalance?: RefundDestinationDetailsCustomerCashBalance | null;
  eps?: RefundDestinationDetailsEps | null;
  euBankTransfer?: RefundDestinationDetailsEuBankTransfer | null;
  gbBankTransfer?: RefundDestinationDetailsGbBankTransfer | null;
  giropay?: RefundDestinationDetailsGiropay | null;
  grabpay?: RefundDestinationDetailsGrabpay | null;
  jpBankTransfer?: RefundDestinationDetailsJpBankTransfer | null;
  klarna?: RefundDestinationDetailsKlarna | null;
  multibanco?: RefundDestinationDetailsMultibanco | null;
  mxBankTransfer?: RefundDestinationDetailsMxBankTransfer | null;
  p24?: RefundDestinationDetailsP24 | null;
  paynow?: RefundDestinationDetailsPaynow | null;
  paypal?: RefundDestinationDetailsPaypal | null;
  pix?: RefundDestinationDetailsPix | null;
  revolut?: RefundDestinationDetailsRevolut | null;
  sofort?: RefundDestinationDetailsSofort | null;
  swish?: RefundDestinationDetailsSwish | null;
  thBankTransfer?: RefundDestinationDetailsThBankTransfer | null;
  type?: string | null;
  usBankTransfer?: RefundDestinationDetailsUsBankTransfer | null;
  wechatPay?: RefundDestinationDetailsWechatPay | null;
  zip?: RefundDestinationDetailsZip | null;
}

export interface RefundDestinationDetailsAffirm {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsAfterpayClearpay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsAlipay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsAmazonPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsAuBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsBlik {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  referenceStatus?: string | null;
}

export interface RefundDestinationDetailsBrBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  referenceStatus?: string | null;
}

export interface RefundDestinationDetailsCard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  referenceStatus?: string | null;
  referenceType?: string | null;
  type?: string | null;
}

export interface RefundDestinationDetailsCashapp {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsCustomerCashBalance {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsEps {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsEuBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  referenceStatus?: string | null;
}

export interface RefundDestinationDetailsGbBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  referenceStatus?: string | null;
}

export interface RefundDestinationDetailsGiropay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsGrabpay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsJpBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  referenceStatus?: string | null;
}

export interface RefundDestinationDetailsKlarna {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsMultibanco {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  referenceStatus?: string | null;
}

export interface RefundDestinationDetailsMxBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  referenceStatus?: string | null;
}

export interface RefundDestinationDetailsP24 {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  referenceStatus?: string | null;
}

export interface RefundDestinationDetailsPaynow {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsPaypal {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsPix {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsRevolut {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsSofort {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsSwish {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  referenceStatus?: string | null;
}

export interface RefundDestinationDetailsThBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  referenceStatus?: string | null;
}

export interface RefundDestinationDetailsUsBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  reference?: string | null;
  referenceStatus?: string | null;
}

export interface RefundDestinationDetailsWechatPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundDestinationDetailsZip {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface RefundNextAction {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  displayDetails?: RefundNextActionDisplayDetails | null;
  type?: string | null;
}

export interface RefundNextActionDisplayDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  emailSent?: RefundNextActionDisplayDetailsEmailSent | null;
  /** @format date-time */
  expiresAt: string;
}

export interface RefundNextActionDisplayDetailsEmailSent {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  emailSentAt: string;
  emailSentTo?: string | null;
}

export interface Review {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  billingZip?: string | null;
  chargeId?: string | null;
  charge?: Charge | null;
  closedReason?: string | null;
  /** @format date-time */
  created: string;
  ipAddress?: string | null;
  ipAddressLocation?: ReviewLocation | null;
  livemode: boolean;
  open: boolean;
  openedReason?: string | null;
  paymentIntentId?: string | null;
  paymentIntent?: PaymentIntent | null;
  reason?: string | null;
  session?: ReviewSession | null;
}

export interface ReviewLocation {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  city?: string | null;
  country?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  region?: string | null;
}

export interface ReviewSession {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  browser?: string | null;
  device?: string | null;
  platform?: string | null;
  version?: string | null;
}

export interface Rule {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  action?: string | null;
  deleted?: boolean | null;
  predicate?: string | null;
}

export interface SetupAttempt {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  applicationId?: string | null;
  application?: Application | null;
  attachToSelf: boolean;
  /** @format date-time */
  created: string;
  customerId?: string | null;
  customer?: Customer | null;
  flowDirections?: string[] | null;
  livemode: boolean;
  onBehalfOfId?: string | null;
  onBehalfOf?: Account | null;
  paymentMethodId?: string | null;
  paymentMethod?: PaymentMethod | null;
  paymentMethodDetails?: SetupAttemptPaymentMethodDetails | null;
  setupError?: StripeError | null;
  setupIntentId?: string | null;
  setupIntent?: SetupIntent | null;
  status?: string | null;
  usage?: string | null;
}

export interface SetupAttemptPaymentMethodDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  acssDebit?: SetupAttemptPaymentMethodDetailsAcssDebit | null;
  amazonPay?: SetupAttemptPaymentMethodDetailsAmazonPay | null;
  auBecsDebit?: SetupAttemptPaymentMethodDetailsAuBecsDebit | null;
  bacsDebit?: SetupAttemptPaymentMethodDetailsBacsDebit | null;
  bancontact?: SetupAttemptPaymentMethodDetailsBancontact | null;
  boleto?: SetupAttemptPaymentMethodDetailsBoleto | null;
  card?: SetupAttemptPaymentMethodDetailsCard | null;
  cardPresent?: SetupAttemptPaymentMethodDetailsCardPresent | null;
  cashapp?: SetupAttemptPaymentMethodDetailsCashapp | null;
  ideal?: SetupAttemptPaymentMethodDetailsIdeal | null;
  klarna?: SetupAttemptPaymentMethodDetailsKlarna | null;
  link?: SetupAttemptPaymentMethodDetailsLink | null;
  paypal?: SetupAttemptPaymentMethodDetailsPaypal | null;
  revolutPay?: SetupAttemptPaymentMethodDetailsRevolutPay | null;
  sepaDebit?: SetupAttemptPaymentMethodDetailsSepaDebit | null;
  sofort?: SetupAttemptPaymentMethodDetailsSofort | null;
  type?: string | null;
  usBankAccount?: SetupAttemptPaymentMethodDetailsUsBankAccount | null;
}

export interface SetupAttemptPaymentMethodDetailsAcssDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsAmazonPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsAuBecsDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsBacsDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsBancontact {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankCode?: string | null;
  bankName?: string | null;
  bic?: string | null;
  generatedSepaDebitId?: string | null;
  generatedSepaDebit?: PaymentMethod | null;
  generatedSepaDebitMandateId?: string | null;
  generatedSepaDebitMandate?: Mandate | null;
  ibanLast4?: string | null;
  preferredLanguage?: string | null;
  verifiedName?: string | null;
}

export interface SetupAttemptPaymentMethodDetailsBoleto {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsCard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  brand?: string | null;
  checks?: SetupAttemptPaymentMethodDetailsCardChecks | null;
  country?: string | null;
  description?: string | null;
  /** @format int64 */
  expMonth?: number | null;
  /** @format int64 */
  expYear?: number | null;
  fingerprint?: string | null;
  funding?: string | null;
  iin?: string | null;
  issuer?: string | null;
  last4?: string | null;
  network?: string | null;
  threeDSecure?: SetupAttemptPaymentMethodDetailsCardThreeDSecure | null;
  wallet?: SetupAttemptPaymentMethodDetailsCardWallet | null;
}

export interface SetupAttemptPaymentMethodDetailsCardChecks {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  addressLine1Check?: string | null;
  addressPostalCodeCheck?: string | null;
  cvcCheck?: string | null;
}

export interface SetupAttemptPaymentMethodDetailsCardPresent {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  generatedCardId?: string | null;
  generatedCard?: PaymentMethod | null;
  offline?: SetupAttemptPaymentMethodDetailsCardPresentOffline | null;
}

export interface SetupAttemptPaymentMethodDetailsCardPresentOffline {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  storedAt?: string | null;
  type?: string | null;
}

export interface SetupAttemptPaymentMethodDetailsCardThreeDSecure {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  authenticationFlow?: string | null;
  electronicCommerceIndicator?: string | null;
  result?: string | null;
  resultReason?: string | null;
  transactionId?: string | null;
  version?: string | null;
}

export interface SetupAttemptPaymentMethodDetailsCardWallet {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  applePay?: SetupAttemptPaymentMethodDetailsCardWalletApplePay | null;
  googlePay?: SetupAttemptPaymentMethodDetailsCardWalletGooglePay | null;
  type?: string | null;
}

export interface SetupAttemptPaymentMethodDetailsCardWalletApplePay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsCardWalletGooglePay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsCashapp {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsIdeal {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bank?: string | null;
  bic?: string | null;
  generatedSepaDebitId?: string | null;
  generatedSepaDebit?: PaymentMethod | null;
  generatedSepaDebitMandateId?: string | null;
  generatedSepaDebitMandate?: Mandate | null;
  ibanLast4?: string | null;
  verifiedName?: string | null;
}

export interface SetupAttemptPaymentMethodDetailsKlarna {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsLink {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsPaypal {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsRevolutPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsSepaDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupAttemptPaymentMethodDetailsSofort {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankCode?: string | null;
  bankName?: string | null;
  bic?: string | null;
  generatedSepaDebitId?: string | null;
  generatedSepaDebit?: PaymentMethod | null;
  generatedSepaDebitMandateId?: string | null;
  generatedSepaDebitMandate?: Mandate | null;
  ibanLast4?: string | null;
  preferredLanguage?: string | null;
  verifiedName?: string | null;
}

export interface SetupAttemptPaymentMethodDetailsUsBankAccount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupIntent {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  applicationId?: string | null;
  application?: Application | null;
  attachToSelf: boolean;
  automaticPaymentMethods?: SetupIntentAutomaticPaymentMethods | null;
  cancellationReason?: string | null;
  clientSecret?: string | null;
  /** @format date-time */
  created: string;
  customerId?: string | null;
  customer?: Customer | null;
  description?: string | null;
  flowDirections?: string[] | null;
  lastSetupError?: StripeError | null;
  latestAttemptId?: string | null;
  latestAttempt?: SetupAttempt | null;
  livemode: boolean;
  mandateId?: string | null;
  mandate?: Mandate | null;
  metadata?: Record<string, string | null>;
  nextAction?: SetupIntentNextAction | null;
  onBehalfOfId?: string | null;
  onBehalfOf?: Account | null;
  paymentMethodId?: string | null;
  paymentMethod?: PaymentMethod | null;
  paymentMethodConfigurationDetails?: SetupIntentPaymentMethodConfigurationDetails | null;
  paymentMethodOptions?: SetupIntentPaymentMethodOptions | null;
  paymentMethodTypes?: string[] | null;
  singleUseMandateId?: string | null;
  singleUseMandate?: Mandate | null;
  status?: string | null;
  usage?: string | null;
}

export interface SetupIntentAutomaticPaymentMethods {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  allowRedirects?: string | null;
  enabled?: boolean | null;
}

export interface SetupIntentNextAction {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  cashappHandleRedirectOrDisplayQrCode?: SetupIntentNextActionCashappHandleRedirectOrDisplayQrCode | null;
  redirectToUrl?: SetupIntentNextActionRedirectToUrl | null;
  type?: string | null;
  useStripeSdk?: SetupIntentNextActionUseStripeSdk | null;
  verifyWithMicrodeposits?: SetupIntentNextActionVerifyWithMicrodeposits | null;
}

export interface SetupIntentNextActionCashappHandleRedirectOrDisplayQrCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  hostedInstructionsUrl?: string | null;
  mobileAuthUrl?: string | null;
  qrCode?: SetupIntentNextActionCashappHandleRedirectOrDisplayQrCodeQrCode | null;
}

export interface SetupIntentNextActionCashappHandleRedirectOrDisplayQrCodeQrCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  expiresAt: string;
  imageUrlPng?: string | null;
  imageUrlSvg?: string | null;
}

export interface SetupIntentNextActionRedirectToUrl {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  returnUrl?: string | null;
  url?: string | null;
}

export interface SetupIntentNextActionUseStripeSdk {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupIntentNextActionVerifyWithMicrodeposits {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  arrivalDate: string;
  hostedVerificationUrl?: string | null;
  microdepositType?: string | null;
}

export interface SetupIntentPaymentMethodConfigurationDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  parent?: string | null;
}

export interface SetupIntentPaymentMethodOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  acssDebit?: SetupIntentPaymentMethodOptionsAcssDebit | null;
  amazonPay?: SetupIntentPaymentMethodOptionsAmazonPay | null;
  bacsDebit?: SetupIntentPaymentMethodOptionsBacsDebit | null;
  card?: SetupIntentPaymentMethodOptionsCard | null;
  cardPresent?: SetupIntentPaymentMethodOptionsCardPresent | null;
  link?: SetupIntentPaymentMethodOptionsLink | null;
  paypal?: SetupIntentPaymentMethodOptionsPaypal | null;
  sepaDebit?: SetupIntentPaymentMethodOptionsSepaDebit | null;
  usBankAccount?: SetupIntentPaymentMethodOptionsUsBankAccount | null;
}

export interface SetupIntentPaymentMethodOptionsAcssDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  currency?: string | null;
  mandateOptions?: SetupIntentPaymentMethodOptionsAcssDebitMandateOptions | null;
  verificationMethod?: string | null;
}

export interface SetupIntentPaymentMethodOptionsAcssDebitMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  customMandateUrl?: string | null;
  defaultFor?: string[] | null;
  intervalDescription?: string | null;
  paymentSchedule?: string | null;
  transactionType?: string | null;
}

export interface SetupIntentPaymentMethodOptionsAmazonPay {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupIntentPaymentMethodOptionsBacsDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  mandateOptions?: SetupIntentPaymentMethodOptionsBacsDebitMandateOptions | null;
}

export interface SetupIntentPaymentMethodOptionsBacsDebitMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupIntentPaymentMethodOptionsCard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  mandateOptions?: SetupIntentPaymentMethodOptionsCardMandateOptions | null;
  network?: string | null;
  requestThreeDSecure?: string | null;
}

export interface SetupIntentPaymentMethodOptionsCardMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  amountType?: string | null;
  currency?: string | null;
  description?: string | null;
  /** @format date-time */
  endDate?: string | null;
  interval?: string | null;
  /** @format int64 */
  intervalCount?: number | null;
  reference?: string | null;
  /** @format date-time */
  startDate: string;
  supportedTypes?: string[] | null;
}

export interface SetupIntentPaymentMethodOptionsCardPresent {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupIntentPaymentMethodOptionsLink {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @deprecated */
  persistentToken?: string | null;
}

export interface SetupIntentPaymentMethodOptionsPaypal {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  billingAgreementId?: string | null;
}

export interface SetupIntentPaymentMethodOptionsSepaDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  mandateOptions?: SetupIntentPaymentMethodOptionsSepaDebitMandateOptions | null;
}

export interface SetupIntentPaymentMethodOptionsSepaDebitMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SetupIntentPaymentMethodOptionsUsBankAccount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  financialConnections?: SetupIntentPaymentMethodOptionsUsBankAccountFinancialConnections | null;
  mandateOptions?: SetupIntentPaymentMethodOptionsUsBankAccountMandateOptions | null;
  verificationMethod?: string | null;
}

export interface SetupIntentPaymentMethodOptionsUsBankAccountFinancialConnections {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  filters?: SetupIntentPaymentMethodOptionsUsBankAccountFinancialConnectionsFilters | null;
  permissions?: string[] | null;
  prefetch?: string[] | null;
  returnUrl?: string | null;
}

export interface SetupIntentPaymentMethodOptionsUsBankAccountFinancialConnectionsFilters {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountSubcategories?: string[] | null;
}

export interface SetupIntentPaymentMethodOptionsUsBankAccountMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  collectionMethod?: string | null;
}

export interface SetupIntentResponse {
  /** @minLength 1 */
  clientSecret: string;
}

export interface Shipping {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  address?: Address | null;
  carrier?: string | null;
  name?: string | null;
  phone?: string | null;
  trackingNumber?: string | null;
}

export interface ShippingRate {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  active: boolean;
  /** @format date-time */
  created: string;
  deliveryEstimate?: ShippingRateDeliveryEstimate | null;
  displayName?: string | null;
  fixedAmount?: ShippingRateFixedAmount | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  taxBehavior?: string | null;
  taxCodeId?: string | null;
  taxCode?: TaxCode | null;
  type?: string | null;
}

export interface ShippingRateDeliveryEstimate {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  maximum?: ShippingRateDeliveryEstimateMaximum | null;
  minimum?: ShippingRateDeliveryEstimateMinimum | null;
}

export interface ShippingRateDeliveryEstimateMaximum {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  unit?: string | null;
  /** @format int64 */
  value: number;
}

export interface ShippingRateDeliveryEstimateMinimum {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  unit?: string | null;
  /** @format int64 */
  value: number;
}

export interface ShippingRateFixedAmount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  currency?: string | null;
  currencyOptions?: Record<string, ShippingRateFixedAmountCurrencyOptions>;
}

export interface ShippingRateFixedAmountCurrencyOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount: number;
  taxBehavior?: string | null;
}

export interface SignalCompanyResponse {
  /** @minLength 1 */
  companyId: string;
  /** @minLength 1 */
  companyName: string;
  /** @minLength 1 */
  websiteUrl: string;
  /** @minLength 1 */
  linkedinUrl: string;
  /** @format int32 */
  foundedIn?: number | null;
  /** @format double */
  score: number;
  headquarters?: HeadquartersResponse | null;
  foundingInfo?: FoundingInfoResponse | null;
  /** @minLength 1 */
  logoUrl: string;
  /** @format int32 */
  listsCount: number;
}

export interface SignalResponse {
  /** @format uuid */
  id: string;
  signalType: SignalType;
  /** @format double */
  score?: number | null;
  company: SignalCompanyResponse;
  body: string;
  filter?: BaseFilter | null;
}

export enum SignalSorting {
  Unknown = 'Unknown',
  MostRecent = 'MostRecent',
  FromOlderToNewer = 'FromOlderToNewer',
}

export enum SignalType {
  Unknown = 'Unknown',
  NewCompanies = 'NewCompanies',
}

export interface SignalsGroupResponse {
  /** @format date-time */
  date: string;
  signals: SignalResponse[];
}

export interface StringStringIEnumerableKeyValuePair {
  key: string;
  value: string[];
}

export interface StripeError {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  charge?: string | null;
  code?: string | null;
  declineCode?: string | null;
  docUrl?: string | null;
  message?: string | null;
  param?: string | null;
  paymentIntent?: PaymentIntent | null;
  paymentMethod?: PaymentMethod | null;
  paymentMethodType?: string | null;
  requestLogUrl?: string | null;
  setupIntent?: SetupIntent | null;
  source?: IPaymentSource | null;
  type?: string | null;
  error?: string | null;
  errorDescription?: string | null;
}

export interface StripeResponse {
  statusCode: HttpStatusCode;
  headers?: StringStringIEnumerableKeyValuePair[] | null;
  /** @format date-time */
  date?: string | null;
  idempotencyKey?: string | null;
  requestId?: string | null;
  content?: string | null;
}

export interface Subscription {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  applicationId?: string | null;
  application?: Application | null;
  /** @format double */
  applicationFeePercent?: number | null;
  automaticTax?: SubscriptionAutomaticTax | null;
  /** @format date-time */
  billingCycleAnchor: string;
  billingCycleAnchorConfig?: SubscriptionBillingCycleAnchorConfig | null;
  billingThresholds?: SubscriptionBillingThresholds | null;
  /** @format date-time */
  cancelAt?: string | null;
  cancelAtPeriodEnd: boolean;
  /** @format date-time */
  canceledAt?: string | null;
  cancellationDetails?: SubscriptionCancellationDetails | null;
  collectionMethod?: string | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  /** @format date-time */
  currentPeriodEnd: string;
  /** @format date-time */
  currentPeriodStart: string;
  customerId?: string | null;
  customer?: Customer | null;
  /** @format int64 */
  daysUntilDue?: number | null;
  defaultPaymentMethodId?: string | null;
  defaultPaymentMethod?: PaymentMethod | null;
  defaultSourceId?: string | null;
  defaultSource?: IPaymentSource | null;
  defaultTaxRates?: TaxRate[] | null;
  description?: string | null;
  discount?: Discount | null;
  discountIds?: string[] | null;
  discounts?: Discount[] | null;
  /** @format date-time */
  endedAt?: string | null;
  invoiceSettings?: SubscriptionInvoiceSettings | null;
  items?: SubscriptionItem[] | null;
  latestInvoiceId?: string | null;
  latestInvoice?: Invoice | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  /** @format date-time */
  nextPendingInvoiceItemInvoice?: string | null;
  onBehalfOfId?: string | null;
  onBehalfOf?: Account | null;
  pauseCollection?: SubscriptionPauseCollection | null;
  paymentSettings?: SubscriptionPaymentSettings | null;
  pendingInvoiceItemInterval?: SubscriptionPendingInvoiceItemInterval | null;
  pendingSetupIntentId?: string | null;
  pendingSetupIntent?: SetupIntent | null;
  pendingUpdate?: SubscriptionPendingUpdate | null;
  scheduleId?: string | null;
  schedule?: SubscriptionSchedule | null;
  /** @format date-time */
  startDate: string;
  status?: string | null;
  testClockId?: string | null;
  testClock?: TestClock | null;
  transferData?: SubscriptionTransferData | null;
  /** @format date-time */
  trialEnd?: string | null;
  trialSettings?: SubscriptionTrialSettings | null;
  /** @format date-time */
  trialStart?: string | null;
}

export interface SubscriptionAutomaticTax {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  enabled: boolean;
  liability?: SubscriptionAutomaticTaxLiability | null;
}

export interface SubscriptionAutomaticTaxLiability {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountId?: string | null;
  account?: Account | null;
  type?: string | null;
}

export interface SubscriptionBillingCycleAnchorConfig {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  dayOfMonth: number;
  /** @format int64 */
  hour?: number | null;
  /** @format int64 */
  minute?: number | null;
  /** @format int64 */
  month?: number | null;
  /** @format int64 */
  second?: number | null;
}

export interface SubscriptionBillingThresholds {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountGte?: number | null;
  resetBillingCycleAnchor?: boolean | null;
}

export interface SubscriptionCancellationDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  comment?: string | null;
  feedback?: string | null;
  reason?: string | null;
}

export interface SubscriptionCreateResponse {
  subscriptionId: string;
  clientSecret: string;
}

export interface SubscriptionInvoiceSettings {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountTaxIdIds?: string[] | null;
  accountTaxIds?: TaxId[] | null;
  issuer?: SubscriptionInvoiceSettingsIssuer | null;
}

export interface SubscriptionInvoiceSettingsIssuer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountId?: string | null;
  account?: Account | null;
  type?: string | null;
}

export interface SubscriptionItem {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  billingThresholds?: SubscriptionItemBillingThresholds | null;
  /** @format date-time */
  created: string;
  deleted?: boolean | null;
  discountIds?: string[] | null;
  discounts?: Discount[] | null;
  metadata?: Record<string, string | null>;
  plan?: Plan | null;
  price?: Price | null;
  /** @format int64 */
  quantity: number;
  subscription?: string | null;
  taxRates?: TaxRate[] | null;
}

export interface SubscriptionItemBillingThresholds {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  usageGte?: number | null;
}

export interface SubscriptionPauseCollection {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  behavior?: string | null;
  /** @format date-time */
  resumesAt?: string | null;
}

export interface SubscriptionPaymentSettings {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  paymentMethodOptions?: SubscriptionPaymentSettingsPaymentMethodOptions | null;
  paymentMethodTypes?: string[] | null;
  saveDefaultPaymentMethod?: string | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  acssDebit?: SubscriptionPaymentSettingsPaymentMethodOptionsAcssDebit | null;
  bancontact?: SubscriptionPaymentSettingsPaymentMethodOptionsBancontact | null;
  card?: SubscriptionPaymentSettingsPaymentMethodOptionsCard | null;
  customerBalance?: SubscriptionPaymentSettingsPaymentMethodOptionsCustomerBalance | null;
  konbini?: SubscriptionPaymentSettingsPaymentMethodOptionsKonbini | null;
  sepaDebit?: SubscriptionPaymentSettingsPaymentMethodOptionsSepaDebit | null;
  usBankAccount?: SubscriptionPaymentSettingsPaymentMethodOptionsUsBankAccount | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsAcssDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  mandateOptions?: SubscriptionPaymentSettingsPaymentMethodOptionsAcssDebitMandateOptions | null;
  verificationMethod?: string | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsAcssDebitMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  transactionType?: string | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsBancontact {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  preferredLanguage?: string | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsCard {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  mandateOptions?: SubscriptionPaymentSettingsPaymentMethodOptionsCardMandateOptions | null;
  network?: string | null;
  requestThreeDSecure?: string | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsCardMandateOptions {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amount?: number | null;
  amountType?: string | null;
  description?: string | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsCustomerBalance {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  bankTransfer?: SubscriptionPaymentSettingsPaymentMethodOptionsCustomerBalanceBankTransfer | null;
  fundingType?: string | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsCustomerBalanceBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  euBankTransfer?: SubscriptionPaymentSettingsPaymentMethodOptionsCustomerBalanceBankTransferEuBankTransfer | null;
  type?: string | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsCustomerBalanceBankTransferEuBankTransfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  country?: string | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsKonbini {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsSepaDebit {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsUsBankAccount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  financialConnections?: SubscriptionPaymentSettingsPaymentMethodOptionsUsBankAccountFinancialConnections | null;
  verificationMethod?: string | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsUsBankAccountFinancialConnections {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  filters?: SubscriptionPaymentSettingsPaymentMethodOptionsUsBankAccountFinancialConnectionsFilters | null;
  permissions?: string[] | null;
  prefetch?: string[] | null;
}

export interface SubscriptionPaymentSettingsPaymentMethodOptionsUsBankAccountFinancialConnectionsFilters {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountSubcategories?: string[] | null;
}

export interface SubscriptionPendingInvoiceItemInterval {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  interval?: string | null;
  /** @format int64 */
  intervalCount: number;
}

export interface SubscriptionPendingUpdate {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  billingCycleAnchor?: string | null;
  /** @format date-time */
  expiresAt: string;
  subscriptionItems?: SubscriptionItem[] | null;
  /** @format date-time */
  trialEnd?: string | null;
  trialFromPlan?: boolean | null;
}

export interface SubscriptionPlanResponse {
  /** @format uuid */
  id: string;
  /** @minLength 1 */
  planName: string;
  /** @format int32 */
  monthlyCredits: number;
  /** @format double */
  price: number;
  isUnpaidPlan: boolean;
}

export interface SubscriptionSchedule {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  applicationId?: string | null;
  application?: Application | null;
  /** @format date-time */
  canceledAt?: string | null;
  /** @format date-time */
  completedAt?: string | null;
  /** @format date-time */
  created: string;
  currentPhase?: SubscriptionScheduleCurrentPhase | null;
  customerId?: string | null;
  customer?: Customer | null;
  defaultSettings?: SubscriptionScheduleDefaultSettings | null;
  endBehavior?: string | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  phases?: SubscriptionSchedulePhase[] | null;
  /** @format date-time */
  releasedAt?: string | null;
  releasedSubscription?: string | null;
  status?: string | null;
  subscriptionId?: string | null;
  subscription?: Subscription | null;
  testClockId?: string | null;
  testClock?: TestClock | null;
}

export interface SubscriptionScheduleCurrentPhase {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format date-time */
  startDate?: string | null;
}

export interface SubscriptionScheduleDefaultSettings {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format double */
  applicationFeePercent?: number | null;
  automaticTax?: SubscriptionScheduleDefaultSettingsAutomaticTax | null;
  billingCycleAnchor?: string | null;
  billingThresholds?: SubscriptionScheduleDefaultSettingsBillingThresholds | null;
  collectionMethod?: string | null;
  defaultPaymentMethodId?: string | null;
  defaultPaymentMethod?: PaymentMethod | null;
  description?: string | null;
  invoiceSettings?: SubscriptionScheduleDefaultSettingsInvoiceSettings | null;
  onBehalfOfId?: string | null;
  onBehalfOf?: Account | null;
  transferData?: SubscriptionScheduleDefaultSettingsTransferData | null;
}

export interface SubscriptionScheduleDefaultSettingsAutomaticTax {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  enabled: boolean;
  liability?: SubscriptionScheduleDefaultSettingsAutomaticTaxLiability | null;
}

export interface SubscriptionScheduleDefaultSettingsAutomaticTaxLiability {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountId?: string | null;
  account?: Account | null;
  type?: string | null;
}

export interface SubscriptionScheduleDefaultSettingsBillingThresholds {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountGte?: number | null;
  resetBillingCycleAnchor?: boolean | null;
}

export interface SubscriptionScheduleDefaultSettingsInvoiceSettings {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountTaxIdIds?: string[] | null;
  accountTaxIds?: TaxId[] | null;
  /** @format int64 */
  daysUntilDue?: number | null;
  issuer?: SubscriptionScheduleDefaultSettingsInvoiceSettingsIssuer | null;
}

export interface SubscriptionScheduleDefaultSettingsInvoiceSettingsIssuer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountId?: string | null;
  account?: Account | null;
  type?: string | null;
}

export interface SubscriptionScheduleDefaultSettingsTransferData {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format double */
  amountPercent?: number | null;
  destinationId?: string | null;
  destination?: Account | null;
}

export interface SubscriptionSchedulePhase {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  addInvoiceItems?: SubscriptionSchedulePhaseAddInvoiceItem[] | null;
  /** @format double */
  applicationFeePercent?: number | null;
  automaticTax?: SubscriptionSchedulePhaseAutomaticTax | null;
  billingCycleAnchor?: string | null;
  billingThresholds?: SubscriptionSchedulePhaseBillingThresholds | null;
  collectionMethod?: string | null;
  couponId?: string | null;
  coupon?: Coupon | null;
  currency?: string | null;
  defaultPaymentMethodId?: string | null;
  defaultPaymentMethod?: PaymentMethod | null;
  defaultTaxRates?: TaxRate[] | null;
  description?: string | null;
  discounts?: SubscriptionSchedulePhaseDiscount[] | null;
  /** @format date-time */
  endDate: string;
  invoiceSettings?: SubscriptionSchedulePhaseInvoiceSettings | null;
  items?: SubscriptionSchedulePhaseItem[] | null;
  metadata?: Record<string, string | null>;
  onBehalfOfId?: string | null;
  onBehalfOf?: Account | null;
  prorationBehavior?: string | null;
  /** @format date-time */
  startDate: string;
  transferData?: SubscriptionSchedulePhaseTransferData | null;
  /** @format date-time */
  trialEnd?: string | null;
}

export interface SubscriptionSchedulePhaseAddInvoiceItem {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  discounts?: SubscriptionSchedulePhaseAddInvoiceItemDiscount[] | null;
  priceId?: string | null;
  price?: Price | null;
  /** @format int64 */
  quantity?: number | null;
  taxRates?: TaxRate[] | null;
}

export interface SubscriptionSchedulePhaseAddInvoiceItemDiscount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  couponId?: string | null;
  coupon?: Coupon | null;
  discountId?: string | null;
  discount?: Discount | null;
  promotionCodeId?: string | null;
  promotionCode?: PromotionCode | null;
}

export interface SubscriptionSchedulePhaseAutomaticTax {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  enabled: boolean;
  liability?: SubscriptionSchedulePhaseAutomaticTaxLiability | null;
}

export interface SubscriptionSchedulePhaseAutomaticTaxLiability {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountId?: string | null;
  account?: Account | null;
  type?: string | null;
}

export interface SubscriptionSchedulePhaseBillingThresholds {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  amountGte?: number | null;
  resetBillingCycleAnchor?: boolean | null;
}

export interface SubscriptionSchedulePhaseDiscount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  couponId?: string | null;
  coupon?: Coupon | null;
  discountId?: string | null;
  discount?: Discount | null;
  promotionCodeId?: string | null;
  promotionCode?: PromotionCode | null;
}

export interface SubscriptionSchedulePhaseInvoiceSettings {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountTaxIdIds?: string[] | null;
  accountTaxIds?: TaxId[] | null;
  /** @format int64 */
  daysUntilDue?: number | null;
  issuer?: SubscriptionSchedulePhaseInvoiceSettingsIssuer | null;
}

export interface SubscriptionSchedulePhaseInvoiceSettingsIssuer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountId?: string | null;
  account?: Account | null;
  type?: string | null;
}

export interface SubscriptionSchedulePhaseItem {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  billingThresholds?: SubscriptionSchedulePhaseItemBillingThresholds | null;
  discounts?: SubscriptionSchedulePhaseItemDiscount[] | null;
  metadata?: Record<string, string | null>;
  planId?: string | null;
  plan?: Plan | null;
  priceId?: string | null;
  price?: Price | null;
  /** @format int64 */
  quantity: number;
  taxRates?: TaxRate[] | null;
}

export interface SubscriptionSchedulePhaseItemBillingThresholds {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format int64 */
  usageGte?: number | null;
}

export interface SubscriptionSchedulePhaseItemDiscount {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  couponId?: string | null;
  coupon?: Coupon | null;
  discountId?: string | null;
  discount?: Discount | null;
  promotionCodeId?: string | null;
  promotionCode?: PromotionCode | null;
}

export interface SubscriptionSchedulePhaseTransferData {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format double */
  amountPercent?: number | null;
  destinationId?: string | null;
  destination?: Account | null;
}

export interface SubscriptionTransferData {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format double */
  amountPercent?: number | null;
  destinationId?: string | null;
  destination?: Account | null;
}

export interface SubscriptionTrialSettings {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  endBehavior?: SubscriptionTrialSettingsEndBehavior | null;
}

export interface SubscriptionTrialSettingsEndBehavior {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  missingPaymentMethod?: string | null;
}

export interface TaxCode {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  description?: string | null;
  name?: string | null;
}

export interface TaxId {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  country?: string | null;
  /** @format date-time */
  created: string;
  customerId?: string | null;
  customer?: Customer | null;
  deleted?: boolean | null;
  livemode: boolean;
  owner?: TaxIdOwner | null;
  type?: string | null;
  value?: string | null;
  verification?: TaxIdVerification | null;
}

export interface TaxIdOwner {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  accountId?: string | null;
  account?: Account | null;
  applicationId?: string | null;
  application?: Application | null;
  customerId?: string | null;
  customer?: Customer | null;
  type?: string | null;
}

export interface TaxIdVerification {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  status?: string | null;
  verifiedAddress?: string | null;
  verifiedName?: string | null;
}

export interface TaxRate {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  active: boolean;
  country?: string | null;
  /** @format date-time */
  created: string;
  description?: string | null;
  displayName?: string | null;
  /** @format double */
  effectivePercentage?: number | null;
  inclusive: boolean;
  jurisdiction?: string | null;
  jurisdictionLevel?: string | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  /** @format double */
  percentage: number;
  state?: string | null;
  taxType?: string | null;
}

export interface TeamMemberNationality {
  /** @minLength 1 */
  name: string;
  /** @format int32 */
  count: number;
}

export interface TeamMetrics {
  /** @format int32 */
  averageAge: number;
  /** @format int32 */
  minAge: number;
  /** @format int32 */
  maxAge: number;
  nationalities: TeamMemberNationality[];
}

export interface TestClock {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format date-time */
  created: string;
  deleted?: boolean | null;
  /** @format date-time */
  deletesAfter: string;
  /** @format date-time */
  frozenTime: string;
  livemode: boolean;
  name?: string | null;
  status?: string | null;
  statusDetails?: TestClockStatusDetails | null;
}

export interface TestClockStatusDetails {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  advancing?: TestClockStatusDetailsAdvancing | null;
}

export interface TestClockStatusDetailsAdvancing {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  /** @format date-time */
  targetFrozenTime: string;
}

export interface Transfer {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format int64 */
  amount: number;
  /** @format int64 */
  amountReversed: number;
  balanceTransactionId?: string | null;
  balanceTransaction?: BalanceTransaction | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  description?: string | null;
  destinationId?: string | null;
  destination?: Account | null;
  destinationPaymentId?: string | null;
  destinationPayment?: Charge | null;
  livemode: boolean;
  metadata?: Record<string, string | null>;
  reversals?: TransferReversal[] | null;
  reversed: boolean;
  sourceTransactionId?: string | null;
  sourceTransaction?: Charge | null;
  sourceType?: string | null;
  transferGroup?: string | null;
}

export interface TransferReversal {
  rawJObject?: Record<string, JToken>;
  stripeResponse?: StripeResponse | null;
  id?: string | null;
  object?: string | null;
  /** @format int64 */
  amount: number;
  balanceTransactionId?: string | null;
  balanceTransaction?: BalanceTransaction | null;
  /** @format date-time */
  created: string;
  currency?: string | null;
  destinationPaymentRefundId?: string | null;
  destinationPaymentRefund?: Refund | null;
  metadata?: Record<string, string | null>;
  sourceRefundId?: string | null;
  sourceRefund?: Refund | null;
  transferId?: string | null;
  transfer?: Transfer | null;
}

export interface UpdateQuerySignalTypesRequest {
  signalTypes: SignalType[];
}

export interface UpdateUserRequest {
  firstName: string;
  lastName: string;
  company: string;
}

export interface UserListRequest {
  /** @format uuid */
  userId: string;
  name: string;
  notes?: string | null;
  companiesIds: string[];
}

export interface UserListResponse {
  /** @format uuid */
  id: string;
  name: string;
  notes?: string | null;
  /** @format int32 */
  companiesCount: number;
  /** @format date-time */
  updatedAt?: string | null;
}

export interface UserListResponsePaginatedResult {
  /** @format int64 */
  totalRecords: number;
  data?: UserListResponse[] | null;
}

export interface UserListUpdateRequest {
  toAdd: string[];
  toDelete: string[];
  notes?: string | null;
  name?: string | null;
}

export interface UserRequest {
  email: string;
  password: string;
  firstName?: string | null;
  lastName?: string | null;
  profileImage?: string | null;
}

export interface UserSubscriptionStateResponse {
  /** @format uuid */
  id: string;
  /** @format int32 */
  remainingCredits: number;
  /** @format int32 */
  monthlyCredits: number;
  /** @format date-time */
  cancellationDate?: string | null;
  /** @format date-time */
  renewalDate: string;
  isUnpaid: boolean;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  public request = async <T = any, _E = any>(
    { secure, path, type, query, format, body, ...params }: FullRequestParams,
  ): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title YunoAI.API
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  accounts = {
    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsRegisterCreate
     * @request POST:/api/accounts/register
     */
    accountsRegisterCreate: (data: UserRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsLoginCreate
     * @request POST:/api/accounts/login
     */
    accountsLoginCreate: (data: UserRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsResetPasswordCreate
     * @request POST:/api/accounts/reset-password
     */
    accountsResetPasswordCreate: (data: UserRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts/reset-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsList
     * @request GET:/api/accounts
     */
    accountsList: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsUpdate
     * @request PUT:/api/accounts
     */
    accountsUpdate: (data: UpdateUserRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsRefreshTokenCreate
     * @request POST:/api/accounts/refresh-token
     */
    accountsRefreshTokenCreate: (data: RefreshTokenRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts/refresh-token`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsGoogleCreate
     * @request POST:/api/accounts/google
     */
    accountsGoogleCreate: (data: GoogleLoginRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/accounts/google`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  billing = {
    /**
     * No description
     *
     * @tags Billing
     * @name PaymentIntent
     * @request POST:/api/billing
     */
    paymentIntent: (params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/api/billing`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name BillingCreateCustomerCreate
     * @request POST:/api/billing/create-customer
     */
    billingCreateCustomerCreate: (params: RequestParams = {}) =>
      this.http.request<Customer, any>({
        path: `/api/billing/create-customer`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name Subscribe
     * @request POST:/api/billing/subscribe
     */
    subscribe: (
      query?: {
        priceId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SubscriptionCreateResponse, any>({
        path: `/api/billing/subscribe`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name BillingSubscriptionsList
     * @request GET:/api/billing/subscriptions
     */
    billingSubscriptionsList: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/billing/subscriptions`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name BillingCancelSubscriptionDelete
     * @request DELETE:/api/billing/cancel-subscription
     */
    billingCancelSubscriptionDelete: (data: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/billing/cancel-subscription`,
        method: 'DELETE',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name BillingUpdateSubscriptionPartialUpdate
     * @request PATCH:/api/billing/update-subscription
     */
    billingUpdateSubscriptionPartialUpdate: (
      query?: {
        subscriptionId?: string;
        priceId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/billing/update-subscription`,
        method: 'PATCH',
        query: query,
        ...params,
      }),
  };
  checkout = {
    /**
     * No description
     *
     * @tags Checkout
     * @name CheckoutCreate
     * @request POST:/api/checkout
     */
    checkoutCreate: (
      query?: {
        priceId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/checkout`,
        method: 'POST',
        query: query,
        ...params,
      }),
  };
  company = {
    /**
     * No description
     *
     * @tags Company
     * @name CompaniesSearchCreate
     * @request POST:/api/companies/search
     */
    companiesSearchCreate: (data: Filter, params: RequestParams = {}) =>
      this.http.request<CompanyResponsePaginatedResult, any>({
        path: `/api/companies/search`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name GetById
     * @request GET:/api/companies/{id}
     */
    getById: (id: string, params: RequestParams = {}) =>
      this.http.request<CompanyResponse, ProblemDetails>({
        path: `/api/companies/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompaniesSimilarCompaniesCreate
     * @request POST:/api/companies/{companyId}/similar-companies
     */
    companiesSimilarCompaniesCreate: (companyId: string, data: Filter, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/companies/${companyId}/similar-companies`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompaniesNotesDetail
     * @request GET:/api/companies/{id}/notes
     */
    companiesNotesDetail: (
      id: string,
      query?: {
        /** @format uuid */
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/companies/${id}/notes`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompaniesNotesCreate
     * @request POST:/api/companies/{id}/notes
     */
    companiesNotesCreate: (id: string, data: NoteRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/companies/${id}/notes`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompaniesNotesUpdate
     * @request PUT:/api/companies/{id}/notes/{noteId}
     */
    companiesNotesUpdate: (id: string, noteId: string, data: NoteRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/companies/${id}/notes/${noteId}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompaniesNotesDelete
     * @request DELETE:/api/companies/{id}/notes/{noteId}
     */
    companiesNotesDelete: (id: string, noteId: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/companies/${id}/notes/${noteId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompaniesSearchInListsCreate
     * @request POST:/api/companies/search-in-lists
     */
    companiesSearchInListsCreate: (data: Filter, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/companies/search-in-lists`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  deepDive = {
    /**
     * No description
     *
     * @tags DeepDive
     * @name CreateDeepDive
     * @request POST:/api/deep-dives
     */
    createDeepDive: (data: CreateDeepDiveRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/deep-dives`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepDive
     * @name GetDeepDives
     * @request GET:/api/deep-dives
     */
    getDeepDives: (
      query?: {
        /** @format int32 */
        Limit?: number;
        /** @format int32 */
        Offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<DeepDiveResponsePaginatedResult, any>({
        path: `/api/deep-dives`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepDive
     * @name GetDeepDive
     * @request GET:/api/deep-dives/{id}
     */
    getDeepDive: (id: string, params: RequestParams = {}) =>
      this.http.request<DeepDiveResponse, any>({
        path: `/api/deep-dives/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepDive
     * @name DeleteDeepDive
     * @request DELETE:/api/deep-dives/{id}
     */
    deleteDeepDive: (id: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/deep-dives/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepDive
     * @name CreateDeepDiveItems
     * @request POST:/api/deep-dives/{id}/details
     */
    createDeepDiveItems: (id: string, data: CreateDeepDiveDetailsRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/deep-dives/${id}/details`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepDive
     * @name GetDeepDiveItems
     * @request GET:/api/deep-dives/{id}/details
     */
    getDeepDiveItems: (
      id: string,
      query?: {
        /** @format int32 */
        Limit?: number;
        /** @format int32 */
        Offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<DeepDiveItemResponsePaginatedResult, any>({
        path: `/api/deep-dives/${id}/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeepDive
     * @name ExportDeepDiveToExcel
     * @request GET:/api/deep-dives/{id}/export-to-excel
     */
    exportDeepDiveToExcel: (id: string, params: RequestParams = {}) =>
      this.http.request<File, any>({
        path: `/api/deep-dives/${id}/export-to-excel`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  documents = {
    /**
     * No description
     *
     * @tags Documents
     * @name DocumentsProfileImageUpdate
     * @request PUT:/api/documents/profile-image
     */
    documentsProfileImageUpdate: (
      data: {
        ContentType?: string;
        ContentDisposition?: string;
        Headers?: Record<string, string[]>;
        /** @format int64 */
        Length?: number;
        Name?: string;
        FileName?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/documents/profile-image`,
        method: 'PUT',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Documents
     * @name DocumentsProfileImageList
     * @request GET:/api/documents/profile-image
     */
    documentsProfileImageList: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/documents/profile-image`,
        method: 'GET',
        ...params,
      }),
  };
  paymentMethods = {
    /**
     * No description
     *
     * @tags PaymentMethods
     * @name GetPaymentMethods
     * @request GET:/api/user-payment-methods
     */
    getPaymentMethods: (params: RequestParams = {}) =>
      this.http.request<PaymentMethodResponse[], any>({
        path: `/api/user-payment-methods`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentMethods
     * @name CreateSetupIntent
     * @request GET:/api/user-payment-methods/setup-intent
     */
    createSetupIntent: (params: RequestParams = {}) =>
      this.http.request<SetupIntentResponse, any>({
        path: `/api/user-payment-methods/setup-intent`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentMethods
     * @name AttachToUser
     * @request PATCH:/api/user-payment-methods/{id}
     */
    attachToUser: (id: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user-payment-methods/${id}`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentMethods
     * @name DetachPaymentMethod
     * @request DELETE:/api/user-payment-methods/{id}
     */
    detachPaymentMethod: (id: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user-payment-methods/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentMethods
     * @name SetAsDefaultToUsers
     * @request PATCH:/api/user-payment-methods/{id}/set-as-default
     */
    setAsDefaultToUsers: (id: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user-payment-methods/${id}/set-as-default`,
        method: 'PATCH',
        ...params,
      }),
  };
  query = {
    /**
     * No description
     *
     * @tags Query
     * @name QueriesList
     * @request GET:/api/queries
     */
    queriesList: (params: RequestParams = {}) =>
      this.http.request<QueryResponse[], any>({
        path: `/api/queries`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Query
     * @name QueriesDelete
     * @request DELETE:/api/queries/{id}
     */
    queriesDelete: (id: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/queries/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Query
     * @name UpdateQuerySignalTypes
     * @request PATCH:/api/queries/{id}
     */
    updateQuerySignalTypes: (id: string, data: UpdateQuerySignalTypesRequest, params: RequestParams = {}) =>
      this.http.request<void, ProblemDetails>({
        path: `/api/queries/${id}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  signal = {
    /**
     * No description
     *
     * @tags Signal
     * @name GetSignals
     * @request GET:/api/signals
     */
    getSignals: (
      query?: {
        Type?: SignalType;
        Sorting?: SignalSorting;
        /** @format int32 */
        Offset?: number;
        ShowDuplicates?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PaginatedSignalsGroupResponse, any>({
        path: `/api/signals`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Signal
     * @name DeleteSignalById
     * @request DELETE:/api/signals/{id}
     */
    deleteSignalById: (id: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/signals/${id}`,
        method: 'DELETE',
        ...params,
      }),
  };
  stripeWebhooks = {
    /**
     * No description
     *
     * @tags StripeWebhooks
     * @name StripeWebhooksCreate
     * @request POST:/api/stripe-webhooks
     */
    stripeWebhooksCreate: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/stripe-webhooks`,
        method: 'POST',
        ...params,
      }),
  };
  subscription = {
    /**
     * No description
     *
     * @tags Subscription
     * @name SubscribeToPlan
     * @request POST:/api/subscriptions/{subscriptionId}
     */
    subscribeToPlan: (subscriptionId: string, params: RequestParams = {}) =>
      this.http.request<SetupIntentResponse, any>({
        path: `/api/subscriptions/${subscriptionId}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name GetDefaultPlans
     * @request GET:/api/subscriptions
     */
    getDefaultPlans: (params: RequestParams = {}) =>
      this.http.request<SubscriptionPlanResponse[], any>({
        path: `/api/subscriptions`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name GetUserSubscriptionState
     * @request GET:/api/subscriptions/user
     */
    getUserSubscriptionState: (params: RequestParams = {}) =>
      this.http.request<UserSubscriptionStateResponse, any>({
        path: `/api/subscriptions/user`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name CancelSubscription
     * @request DELETE:/api/subscriptions/user
     */
    cancelSubscription: (
      query?: {
        /** @format uuid */
        subscriptionId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/subscriptions/user`,
        method: 'DELETE',
        query: query,
        ...params,
      }),
  };
  teams = {
    /**
     * No description
     *
     * @tags Teams
     * @name GetCompanyTeamMembers
     * @request GET:/api/companies/{companyId}/team
     */
    getCompanyTeamMembers: (
      companyId: string,
      query?: {
        /** @format int32 */
        Offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PeopleResponseInfiniteQueryResponse, any>({
        path: `/api/companies/${companyId}/team`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name GetCompanyTeamMetrics
     * @request GET:/api/companies/{companyId}/team/metrics
     */
    getCompanyTeamMetrics: (companyId: string, params: RequestParams = {}) =>
      this.http.request<TeamMetrics, any>({
        path: `/api/companies/${companyId}/team/metrics`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name UnlockTeam
     * @request POST:/api/companies/{companyId}/team/unlock
     */
    unlockTeam: (companyId: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/companies/${companyId}/team/unlock`,
        method: 'POST',
        ...params,
      }),
  };
  userList = {
    /**
     * No description
     *
     * @tags UserList
     * @name UserListsCreate
     * @request POST:/api/user-lists
     */
    userListsCreate: (data: UserListRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user-lists`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsList
     * @request GET:/api/user-lists
     */
    userListsList: (
      query?: {
        /** @format uuid */
        userId?: string;
        /** @format int32 */
        Limit?: number;
        /** @format int32 */
        Offset?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<UserListResponsePaginatedResult, any>({
        path: `/api/user-lists`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsCompaniesCreate
     * @request POST:/api/user-lists/companies
     */
    userListsCompaniesCreate: (data: AddCompaniesToUserListRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user-lists/companies`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsCompaniesList
     * @request GET:/api/user-lists/companies
     */
    userListsCompaniesList: (
      query?: {
        /** @format int32 */
        offset?: number;
        /** @format int32 */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/user-lists/companies`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name GetListsByUserIdForDeepDive
     * @request GET:/api/user-lists/deep-dive
     */
    getListsByUserIdForDeepDive: (params: RequestParams = {}) =>
      this.http.request<DeepDiveUserListResponse[], any>({
        path: `/api/user-lists/deep-dive`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsUpdate
     * @request PUT:/api/user-lists/{id}
     */
    userListsUpdate: (id: string, data: UserListUpdateRequest, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user-lists/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsDelete
     * @request DELETE:/api/user-lists/{id}
     */
    userListsDelete: (id: string, params: RequestParams = {}) =>
      this.http.request<void, ProblemDetails>({
        path: `/api/user-lists/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsDetail
     * @request GET:/api/user-lists/{id}
     */
    userListsDetail: (id: string, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/api/user-lists/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsCompaniesDetail
     * @request GET:/api/user-lists/{id}/companies
     */
    userListsCompaniesDetail: (
      id: string,
      query?: {
        /** @format int32 */
        offset?: number;
        /** @format int32 */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/user-lists/${id}/companies`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserList
     * @name UserListsCompaniesCheckList
     * @request GET:/api/user-lists/companies/check
     */
    userListsCompaniesCheckList: (
      query?: {
        /** @format uuid */
        userId?: string;
        companyId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/api/user-lists/companies/check`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
}
