import { cn } from '@/lib/utils';
import { ComponentProps, FC, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip as BaseTooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger } from './BaseTooltip';

type TooltipProps = PropsWithChildren & {
  content?: ReactNode;
  asChild?: boolean;
  triggerClassNames?: string;
} & Omit<ComponentProps<typeof TooltipContent>, 'content'>;

export const Tooltip: FC<TooltipProps> = ({
  asChild,
  children,
  content,
  className,
  triggerClassNames,
  ...contentProps
}) => {
  const { t } = useTranslation();

  const tooltipContent = typeof content === 'string' ? t(content) : content;

  return (
    <TooltipProvider>
      <BaseTooltip>
        <TooltipTrigger type="button" tabIndex={-1} asChild={asChild} className={triggerClassNames}>
          {children}
        </TooltipTrigger>
        {content ? (
          <TooltipPortal>
            <TooltipContent
              {...contentProps}
              className={cn('z-[99999] max-w-96 whitespace-pre-wrap bg-white text-xs text-grey-900', className)}
            >
              {tooltipContent}
            </TooltipContent>
          </TooltipPortal>
        ) : null}
      </BaseTooltip>
    </TooltipProvider>
  );
};
