import { cn } from '@/lib/utils';
import { Spinner } from '@components/Spinner';
import type { Header } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import type { ForwardedRef, MouseEvent, ReactElement } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { TableCell, TableHeaderCell, TableRow } from '../parts';
import { TableBaseAPI, TableBaseProps } from './types';

const TableBaseComp = <T extends { id?: string }>(
  {
    id,
    isLoading = false,
    className,
    tableClassName,
    innerWrapperClassName,
    table,
    footer,
    enableRowSelection = false,
    onScroll,
    renderLastRow: renderFooter,
    rowHeight = 56,
    notFound = 'No Data',
    onRowClick,
  }: TableBaseProps<T>,
  ref: ForwardedRef<TableBaseAPI>,
) => {
  const innerRef = useRef<HTMLDivElement>(null);

  const onHeaderClick = (e: MouseEvent, header: Header<T, unknown>) => {
    const sortingHandler = header.column.getToggleSortingHandler();
    if (sortingHandler) sortingHandler(e);
  };

  const rows = table.getRowModel().rows;

  const { getTotalSize, getVirtualItems, scrollToOffset } = useVirtualizer({
    count: rows.length,
    getScrollElement: () => innerRef.current,
    estimateSize: () => rowHeight,
    overscan: 10,
  });

  const totalSize = getTotalSize();
  const virtualItems = getVirtualItems();

  useImperativeHandle(ref, () => {
    return {
      reactTableRef: innerRef,
      scrollToOffset,
    };
  }, []);

  const paddingTop = virtualItems.length > 0 ? virtualItems?.[0]?.start || 0 : 0;
  const paddingBottom = virtualItems.length > 0 ? totalSize - (virtualItems?.[virtualItems.length - 1]?.end || 0) : 0;

  return (
    <>
      <div className={cn('mt-4 flex w-full flex-1 flex-col justify-between overflow-hidden bg-inherit', className)}>
        <div className="relative flex flex-1 overflow-hidden">
          <div
            className={cn('relative flex flex-1 flex-col items-center overflow-y-auto', innerWrapperClassName)}
            ref={innerRef}
            onScroll={onScroll}
          >
            <table className={twMerge('relative w-full border-separate border-spacing-0', tableClassName)}>
              <thead className="sticky left-0 top-0 z-10 bg-white drop-shadow-md">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr data-testid={`${id}-header`} key={headerGroup.id}>
                    {headerGroup.headers.map((header, myIndex) => {
                      return <TableHeaderCell<T> key={header.id} header={header} onHeaderClick={onHeaderClick} />;
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="relative" style={{ height: `${getTotalSize()}px` }}>
                {paddingTop > 0 && (
                  <tr>
                    <td style={{ height: `${paddingTop}px` }} />
                  </tr>
                )}
                {virtualItems.map((virtualRow, index) => {
                  const row = rows[virtualRow.index];

                  return (
                    <TableRow<T>
                      key={virtualRow.key}
                      index={virtualRow.index}
                      enableRowSelection={enableRowSelection}
                      row={row}
                      style={{
                        height: `${virtualRow.size}px`,
                      }}
                      onClick={onRowClick}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return <TableCell<T> key={cell.id} cell={cell} />;
                      })}
                    </TableRow>
                  );
                })}
                {paddingBottom > 0 && (
                  <tr>
                    <td style={{ height: `${paddingBottom}px` }} />
                  </tr>
                )}
              </tbody>
              {!!renderFooter && <tfoot>{renderFooter}</tfoot>}
            </table>
            {isLoading ? (
              <Spinner className="mt-5" size="lg" />
            ) : rows.length === 0 ? (
              <div className="pt-5">{notFound}</div>
            ) : null}
          </div>
        </div>
        {footer}
      </div>
    </>
  );
};

export const TableBase = forwardRef(TableBaseComp) as <T>(
  props: TableBaseProps<T> & { ref?: ForwardedRef<TableBaseAPI> },
) => ReactElement;
