import { IconButtonWithModal } from '@global/components/ButtonWithModal';
import { Trash2 } from 'lucide-react';
import { FC, useState } from 'react';
import { ConfirmListDeletionModalContent } from './ConfirmListDeletionModalContent';

type DeleteListButtonProps = {
  id: string;
};

export const DeleteListButton: FC<DeleteListButtonProps> = ({ id }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleCloseModal = () => setIsOpen(false);

  return (
    <IconButtonWithModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      content={<ConfirmListDeletionModalContent id={id} onClose={handleCloseModal} />}
      icon={<Trash2 className="size-5" />}
      modalProps={{
        className: 'lg:w-[430px] pt-0',
        contentWrapperClassName: 'pt-4',
      }}
    />
  );
};
