import { QueryKeys } from '@/api/entities/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { httpCreateUserList } from '../api/UserListsAPI';

function CreateListPopup({ handleModalToggle }) {
  const [newListName, setNewListName] = useState('');
  const [newListNote, setNewListNotes] = useState('');

  const [error, setError] = useState('');

  const validateListName = () => {
    if (newListName.length < 4) {
      setError('List name must be at least 4 characters long.');
    } else {
      setError('');
    }
  };

  const handleListNameChange = (e) => {
    setNewListName(e.target.value);
  };

  const handleListNotesChange = (e) => {
    setNewListNotes(e.target.value);
  };

  const queryClient = useQueryClient();

  const handleCreateList = () => {
    httpCreateUserList({ name: newListName, notes: newListNote, userId: localStorage.getItem('userId') })
      .then((res) => {
        handleModalToggle();
        setNewListName('');
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserLists],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [selected, setSelected] = useState('');

  const handleChangeListSelected = (event) => {
    setSelected(event.target.value);
  };

  const handlClosePopUp = (e) => {
    if (e.target.id === 'ModelContainer') {
      handleModalToggle();
    }
  };

  return (
    <div
      className="fixed left-0 top-0 z-[2000] flex h-full w-full items-center justify-center bg-black bg-opacity-50"
      id="ModelContainer"
      onClick={handlClosePopUp}
    >
      <div className="w-[80%] rounded-lg bg-base-200 lg:w-[50%]">
        <div className="flex h-10 w-full items-center justify-end rounded-tl-lg rounded-tr-lg bg-custom-gradient">
          <IoClose size={20} className="mr-2 cursor-pointer text-base-200" onClick={handleModalToggle} />
        </div>

        <div
          className={`${
            selected ? 'pointer-events-none opacity-50' : ''
          } mb-10 mt-7 flex w-full flex-col items-start justify-center rounded-lg px-10`}
        >
          <h2 className="mb-4 text-2xl font-bold">Create new list</h2>
          <div className="label">
            <span className="label-text text-base font-semibold opacity-70">
              List Name <span className="text-red-500">*</span>
            </span>
          </div>
          <input
            type="text"
            placeholder="Enter list name..."
            value={newListName}
            onChange={handleListNameChange}
            className={'input input-bordered w-full' + `${error && 'border-1 border-error'}`}
            onBlur={validateListName}
          />
          {error && <p className="mt-2 text-xs text-error">{error}</p>}
          <div className="label">
            <span className="label-text mt-4 text-base font-semibold opacity-70">Notes</span>
          </div>
          <textarea
            maxLength="255"
            className="textarea textarea-bordered mb-4 w-full"
            onChange={handleListNotesChange}
            placeholder="Insert notes here"
            rows="4"
          ></textarea>
        </div>

        <div className="mb-10 flex items-center justify-center gap-4">
          <button
            onClick={handleCreateList}
            disabled={selected == '' && newListName.length < 4}
            className={`"btn w-1/2 ${
              selected == '' && newListName.length < 4
                ? 'bg-neutral text-base-content'
                : 'bg-custom-gradient text-base-200'
            } transition-transform" btn flex items-center shadow-md hover:scale-102`}
          >
            <FaSave size={20} className="" />
            <span className="text-[18px] font-medium">Save</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateListPopup;
