import { EnumLike, z, ZodObject, ZodRawShape } from 'zod';
import { ValidationMessages } from './validationMessages';

// Deprecated
export const selectItemFormScheme = z.object({
  label: z.string(),
  value: z.string(),
});

export const baseSelectFormSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export const getExtendedBaseSelectFormSchema = <T extends ZodRawShape>(extraSchema: ZodObject<T>) =>
  baseSelectFormSchema.merge(extraSchema);

export const selectValidation = baseSelectFormSchema.nullable().transform((v) => v?.value || null);

export const requiredSelectValidation = z
  .object(baseSelectFormSchema.shape, {
    errorMap: (_, _ctx) => ({ message: ValidationMessages.required }),
  })
  .nullable()
  .refine((v) => v !== null, ValidationMessages.required)
  .transform((option) => option!.value!);

export const getExtendedRequiredSelectValidation = <
  T extends ReturnType<typeof getExtendedBaseSelectFormSchema>['shape'],
>(
  schema: ZodObject<T>,
) => {
  return getExtendedBaseSelectFormSchema(schema)
    .nullable()
    .refine((v) => v !== null, ValidationMessages.required);
};

export const multiSelectValidation = baseSelectFormSchema
  .array()
  .nullable()
  .transform((values) => (values ? values.map((v) => v.value) : []));

export const requiredMultiSelectValidation = z
  .object(baseSelectFormSchema.shape, {
    errorMap: (_, _ctx) => ({ message: ValidationMessages.required }),
  })
  .array();

const baseEnumSelectFormSchema = <T extends EnumLike>(enumType: T) =>
  z.object({
    value: z.nativeEnum(enumType),
    label: z.string(),
  });

export const selectValidationFromEnum = <T extends EnumLike>(enumType: T) =>
  baseEnumSelectFormSchema(enumType)
    .nullable()
    .transform((v) => v?.value || null);

export const requiredSelectValidationFromEnum = <T extends EnumLike>(source: T) =>
  z
    .object(baseEnumSelectFormSchema(source).shape, {
      errorMap: (_, _ctx) => {
        return { message: ValidationMessages.required };
      },
    })
    .nullable()
    .refine((v) => v !== null, ValidationMessages.required)
    .transform((option) => option!.value!);
