import { api } from '@/api/apiV2';
import { UserListResponsePaginatedResult } from '@/api/generated/Api';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';

type UserListsQueryArgs = Parameters<typeof api.userList.userListsList>[0];

type UseUserListsArgs = {
  signal?: AbortSignal;
  onSuccess?: (data: UserListResponsePaginatedResult) => void;
  queryArgs?: Omit<NonNullable<UserListsQueryArgs>, 'userId'>;
};

export const useUserLists = ({ signal, onSuccess, queryArgs }: UseUserListsArgs) => {
  const args = queryArgs || { Limit: 100 };

  const { data, isFetching } = useQuery({
    queryKey: [QueryKeys.UserLists, { ...args }],
    queryFn: async () => {
      const response = await api.userList.userListsList(
        {
          ...args,
          userId: localStorage.getItem('userId') ?? '',
        },
        {
          signal,
        },
      );

      const result = response.data;
      onSuccess?.(result);

      return result;
    },
  });

  return {
    userLists: data?.data ?? [],
    total: data?.totalRecords,
    isUserListsLoading: isFetching,
  };
};
