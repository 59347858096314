import { Button } from '@components/Button';
import { Typography } from '@components/Typography';
import { FC, SyntheticEvent } from 'react';

type DeletionErrorModalContentProps = {
  onClose: (e: SyntheticEvent<HTMLButtonElement>) => void;
};

export const DeletionErrorModalContent: FC<DeletionErrorModalContentProps> = ({ onClose }) => {
  const handleClose = (e: SyntheticEvent<HTMLButtonElement, Event>) => {
    e.stopPropagation();
    onClose(e);
  };

  return (
    <div className="flex flex-col items-center gap-5 text-center">
      <Typography text="userListNotDeleted" variant="doubleXl/bold" className="capitalize text-grey-900" />
      <Typography text="userListCannotBeDeleted" variant="lg/regular" className="text-grey-900" />
      <div className="flex h-12 w-full">
        <Button text="close" intent={'secondary'} onClick={handleClose} className="size-full items-center" />
      </div>
    </div>
  );
};
