import { TranslationKeys } from '@/i18n';
import { IconButtonWithModal } from '@global/components/ButtonWithModal';
import { Trash2 } from 'lucide-react';
import { FC, useState } from 'react';
import { DeleteModalContent } from './DeleteModalContent';

type DeleteButtonProps = {
  entity: string;
  deleteFunc: (signal: AbortSignal | undefined) => Promise<void>;
  onSuccess?: () => Promise<void>;
  disabled?: boolean;
  tooltip?: TranslationKeys;
};

export const DeleteButton: FC<DeleteButtonProps> = ({ entity, deleteFunc, onSuccess, disabled, tooltip }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleCloseModal = () => setIsOpen(false);

  return (
    <IconButtonWithModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      content={
        <DeleteModalContent entity={entity} onSubmit={deleteFunc} onClose={handleCloseModal} onSuccess={onSuccess} />
      }
      icon={<Trash2 className="size-5" />}
      disabled={disabled}
      tooltip={disabled ? tooltip : undefined}
      modalProps={{
        className: 'lg:w-[430px] pt-0',
        contentWrapperClassName: 'pt-4',
      }}
    />
  );
};
