import { DeepDiveItemStatus } from '@/api/generated/Api';
import { cn } from '@/lib/utils';
import { Typography } from '@components/Typography';
import { FC } from 'react';

type DeepDiveItemQualificationProps = {
  status: DeepDiveItemStatus;
};

export const DeepDiveItemQualification: FC<DeepDiveItemQualificationProps> = ({ status }) => {
  const isSuitable = status === DeepDiveItemStatus.Suitable;

  return (
    <span className={cn('flex w-fit rounded-full px-3 py-1', isSuitable ? 'bg-status-success' : 'bg-status-error')}>
      <Typography
        text={isSuitable ? 'yes' : 'no'}
        className="text-[10px] font-bold uppercase leading-[10px] text-white"
      />
    </span>
  );
};
