import { FC } from 'react';
import { DeepDiveItemsTable } from '../../components/DeepDiveItemsTable';
import { DeepDiveDetailsHeader } from './parts/DeepDiveDetailsHeader';
import { DeepDiveInformation } from './parts/DeepDiveInformation';

export const DeepDiveDetailsPage: FC = () => {
  return (
    <div className="grid h-full grid-rows-[max-content,max-content,1fr] gap-y-4">
      <DeepDiveDetailsHeader />
      <DeepDiveInformation />
      <DeepDiveItemsTable />
    </div>
  );
};
