import { useDeleteUserList } from '@/api/entities/userLists';
import { useAbortController } from '@/hooks';
import { Button } from '@components/Button';
import { Typography } from '@components/Typography';
import { PageTitle } from '@global/components/pages/PageTitle';
import { FC, SyntheticEvent, useState } from 'react';
import { DeletionErrorModalContent } from './DeletionErrorModalContent';

type ConfirmListDeletionModalContentProps = {
  id: string;
  onClose: VoidFunction;
};

export const ConfirmListDeletionModalContent: FC<ConfirmListDeletionModalContentProps> = ({ id, onClose }) => {
  const [isUserListCannotBeDeleted, setIsUserListCannotBeDeleted] = useState(false);
  const handleCloseModal = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose();
  };

  const { signal } = useAbortController();
  const { deleteUserList, isUserListDeleting } = useDeleteUserList({
    id,
    signal,
    onSuccess: onClose,
    onError: () => setIsUserListCannotBeDeleted(true),
  });

  if (isUserListCannotBeDeleted) {
    return <DeletionErrorModalContent onClose={handleCloseModal} />;
  }

  return (
    <div className="flex flex-col items-center gap-5 text-center">
      <PageTitle text="deleteUserList" />
      <Typography
        text={'areYouSureDeleteUserList'}
        variant="lg/regular"
        className={'whitespace-pre-wrap text-grey-900 first-letter:capitalize'}
      />
      <footer className="mt-4 grid h-12 w-full grid-cols-2 gap-4 *:h-full *:w-full *:items-center">
        <Button onClick={handleCloseModal} intent={'secondary'} text="cancel" />
        <Button
          isLoading={isUserListDeleting}
          disabled={isUserListDeleting}
          onClick={() => deleteUserList()}
          text="yesDelete"
        />
      </footer>
    </div>
  );
};
