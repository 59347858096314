import { api } from '@/api/apiV2';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { MutationKeys, QueryKeys } from '../queryKeys';

type UseDeleteUserListArgs = {
  id: string;
  signal?: AbortSignal;
  onSuccess?: (id: string) => void;
  onError?: VoidFunction;
};

export const useDeleteUserList = ({ id, signal, onSuccess, onError }: UseDeleteUserListArgs) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationKey: [MutationKeys.DeleteUserList],
    mutationFn: async () => {
      try {
        await api.userList.userListsDelete(id, { signal });

        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserLists],
        });

        onSuccess?.(id);

        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.DeepDivesUserLists],
        });
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 409) {
          onError?.();
        }
      }
    },
  });

  return {
    deleteUserList: mutateAsync,
    isUserListDeleting: isPending,
  };
};
