import { cn } from '@/lib/utils';
import { SvgIcon } from '@components/SvgIcon';
import { LocalizedText, Typography } from '@components/Typography';
import { FC, KeyboardEventHandler, SyntheticEvent } from 'react';
import { downloadFile } from './api';

type DownloadButtonProps = {
  url: string;
  signal?: AbortSignal;
  text?: LocalizedText;
};

export const DownloadButton: FC<DownloadButtonProps> = ({ url, text, signal }) => {
  const handleClick = async (e: SyntheticEvent<HTMLButtonElement, Event>) => {
    e.stopPropagation();
    await downloadFile({ url, signal });
  };

  const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();
  };

  return (
    <button
      onClick={handleClick}
      className={cn(
        'rounded bg-gradient-to-r from-gradient-from to-gradient-to px-2 py-1 shadow-md outline-grey-900',
        text
          ? 'px-6 py-[10px]'
          : 'hover:scale-125 motion-safe:transition-transform motion-safe:delay-100 motion-safe:duration-300 motion-reduce:transition-none',
      )}
      onKeyDown={handleKeyDown}
    >
      {text ? (
        <Typography {...text} className="text-xs font-bold uppercase leading-[17px] text-white" />
      ) : (
        <SvgIcon name="DownloadCloud" />
      )}
    </button>
  );
};
