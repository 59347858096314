export enum QueryKeys {
  UserInfo = 'user-info',
  ProfileImage = 'profile-image',
  Queries = 'queries',
  UserLists = 'user-lists',
  Signals = 'signals',
  TeamMetrics = 'team-metrics',
  TeamMembers = 'team-members',
  Companies = 'companies',
  BillingFeatureStatus = 'billing-feature-status',
  BillingFeatureGate = 'billing-feature-gate',
  BillingPlans = 'billing-plans',
  UserSubscriptionState = 'user-subscription-state',
  PaymentMethods = 'payment-methods',
  CreatePaymentMethodSetupIntent = 'create--payment-method-setup-intent',
  DeepDives = 'deep-dives',
  DeepDiveDetails = 'deep-dive-details',
  DeepDivesUserLists = 'deep-dives-user-lists',
  DeepDiveResults = 'deep-dive-results',
}

export enum MutationKeys {
  UnlockTeamFeature = 'unlock-team-feature',
  AttachPaymentMethod = 'attach-payment-method',
  SetDefaultPaymentMethod = 'set-default-payment-method',
  DetachPaymentMethod = 'detach-payment-method',
  SubscribeToPlan = 'subscribe-to-plan',
  CancelSubscription = 'cancel-subscription',
  UpdateUserList = 'update-user-list',
  DeleteUserList = 'delete-user-list',
  DeleteDeepDive = 'delete-deep-dive',
  CreateDeepDive = 'create-deep-dive',
}
