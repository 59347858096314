export const getCurrentQueryParams = <T extends Record<string, string>>(url = '') => {
  const queryString = (url || window.location.search).split('?')[1];
  const urlParams = new URLSearchParams(queryString);

  const entries = urlParams.entries();
  const params = {} as T;
  for (const entry of entries) {
    // @ts-expect-error: Type 'string' cannot be used to index type 'T'
    params[entry[0]] = entry[1];
  }

  return params;
};

export const getIdFromQueryParams = (url = '', searchedParamName = 'id'): string | null => {
  const queryString = (url || window.location.search).split('?')[1];
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(searchedParamName);
};
