import { cn } from '@/lib/utils';
import { LocalizedText, Typography } from '@components/Typography';
import { FC, PropsWithChildren } from 'react';

type FormFieldProps = PropsWithChildren & LocalizedText & { className?: string; required?: boolean };

export const FormField: FC<FormFieldProps> = ({ children, className, required, ...textProps }) => (
  <div className="flex w-full flex-col gap-2">
    <Typography
      {...textProps}
      className={cn(
        'text-xs capitalize leading-[17px] text-grey-900',
        required ? "after:ps-0.5 after:content-['*']" : undefined,
        className,
      )}
    />
    {children}
  </div>
);
