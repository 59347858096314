import { QueryKeys } from '@/api/entities/queryKeys';
import { useUpdateUserList } from '@/api/entities/userLists';
import { UserListResponse } from '@/api/generated/Api';
import { useAbortController } from '@/hooks';
import { BaseModal } from '@components/BaseModal/BaseModal';
import { Typography } from '@components/Typography';
import { queryClient } from '@global/queryClient';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { UpdateUserListFormInput, UpdateUserListFormOutput, updateUserListValidationSchema } from './validator';

type EditQueryModalProps = {
  userList: UserListResponse | null;
  onClose: VoidFunction;
};

export const EditListModal: FC<EditQueryModalProps> = ({ userList, onClose }) => {
  const { signal } = useAbortController();

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm<UpdateUserListFormInput, unknown, UpdateUserListFormOutput>({
    resolver: zodResolver(updateUserListValidationSchema),
    mode: 'onChange',
    defaultValues: {
      name: userList?.name as string,
    },
  });

  const { updateUserList, isUserListUpdating } = useUpdateUserList({
    id: userList?.id as string,
    signal,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.UserLists],
      });
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.DeepDivesUserLists],
      });
      onClose();
    },
  });

  const onSubmit: SubmitHandler<UpdateUserListFormOutput> = async (data) => {
    await updateUserList({
      ...data,
      toAdd: [],
      toDelete: [],
    });
  };

  return (
    <BaseModal isOpen={!!userList} onCloseHandler={onClose}>
      <form className="contents" onSubmit={handleSubmit(onSubmit)}>
        <Typography text={'editList'} tag="h2" variant="doubleXl/bold" className="capitalize text-grey" />
        <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange } }) => (
            <input type="text" value={value} onChange={onChange} className="input input-bordered w-full" />
          )}
        />
        <footer className="flex items-center justify-center gap-4">
          <button
            type="submit"
            disabled={!isDirty || Object.keys(errors).length > 0}
            className='transition-transform" btn flex w-1/2 items-center bg-custom-gradient text-base-200 shadow-md hover:scale-102 disabled:bg-neutral'
          >
            {isUserListUpdating ? (
              <span className="loading loading-dots loading-md"></span>
            ) : (
              <div className="flex items-center gap-2">
                <FaSave size={20} />
                <Typography text="save" variant="lg/medium" className="capitalize" />
              </div>
            )}
          </button>
        </footer>
      </form>
    </BaseModal>
  );
};
