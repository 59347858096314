import { cn } from '@/lib/utils';
import { cva } from 'class-variance-authority';
import type { DetailedHTMLProps, FC, ForwardedRef, InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export type InputProps = {
  errorMsg?: string;
  wrapperClassName?: string;
  isLoading?: boolean;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const styles = cva(
  'h-[39px] w-full rounded-md border-disabled bg-fill-input font-bold px-4 placeholder:text-black/50 disabled:cursor-not-allowed disabled:text-black/25 text-grey-900 focus-visible:outline-grey-700',
  {
    variants: {
      hasError: {
        true: 'border-red-500',
      },
    },
  },
);

const InputComp = (
  { errorMsg, wrapperClassName, className, placeholder, isLoading, ...props }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const { t } = useTranslation();

  return (
    <div className={cn('relative w-full text-[12px] leading-[17px]', wrapperClassName)}>
      <input
        ref={ref}
        type="text"
        className={cn(styles({ hasError: !!errorMsg }), className)}
        placeholder={t(placeholder) ?? undefined}
        spellCheck
        {...props}
      />
      {errorMsg ? (
        <p role="alert" className="text-red-500">
          {errorMsg}
        </p>
      ) : null}
    </div>
  );
};

export const Input = forwardRef<HTMLInputElement, InputProps>(InputComp) as FC<InputProps>;
