import { QueryKeys } from '@/api/entities/queryKeys';
import { useUserLists } from '@/api/entities/userLists';
import { useAbortController } from '@/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { MdFileUpload } from 'react-icons/md';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { ClipLoader } from 'react-spinners';
import CardList from '../components/CardList';
import CreateListPopup from '../components/CreateListPopup';

const MyLists = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const { signal } = useAbortController();

  const { lists, isUserListsLoading } = useUserLists({
    signal,
  });

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleDeleteList = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.UserLists],
    });
  };

  return (
    <div className="flex w-full flex-col gap-7">
      {isModalOpen && <CreateListPopup handleModalToggle={handleModalToggle} />}
      <div className="text-3xl font-semibold">My Lists</div>
      <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
        <div className="w-full sm:w-1/2">
          <button
            onClick={() => setIsModalOpen(true)}
            className="btn flex w-full items-center text-base-content shadow-md transition-transform hover:scale-102 hover:border-none hover:bg-base-200"
          >
            <FiPlusCircle size="24" className="cursor-pointer duration-500" />
            <span>CREATE NEW LIST</span>
          </button>
        </div>
        <div className="w-full sm:w-1/2">
          <button
            disabled={true}
            className="btn flex w-full items-center shadow-md disabled:bg-base-200 disabled:text-neutral"
          >
            <MdFileUpload size="24" />
            <span>UPLOAD NEW LIST</span>
          </button>
        </div>
      </div>
      {isUserListsLoading ? (
        <div className="flex items-center justify-center pt-20">
          <ClipLoader color="primary" size="40px" />
        </div>
      ) : (
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="1rem">
            {lists?.length > 0 ? (
              lists.map((list, index) => <CardList key={index} list={list} handleDeleteList={handleDeleteList} />)
            ) : (
              <div className="justify-left flex w-full items-center text-xl font-semibold text-base-content">
                0 lists found
              </div>
            )}
          </Masonry>
        </ResponsiveMasonry>
      )}
    </div>
  );
};

export default MyLists;
