import { useCreatePaymentMethodSetupIntent } from '@/api/entities/paymentMethods';
import { BaseModal } from '@components/BaseModal/BaseModal';
import { Typography } from '@components/Typography';
import { Loadable } from '@global/components/Loadable';
import { getStripePromise } from '@global/stripe';
import { Elements } from '@stripe/react-stripe-js';
import { FC } from 'react';
import { AddPaymentMethodModalContent } from './AddPaymentMethodModalContent';

type AddPaymentMethodModalProps = {
  onCloseHandler: VoidFunction;
};

export const AddPaymentMethodModal: FC<AddPaymentMethodModalProps> = ({ onCloseHandler }) => {
  const { data, isLoading } = useCreatePaymentMethodSetupIntent();

  const stripePromise = getStripePromise();

  return (
    <>
      <BaseModal isOpen onCloseHandler={onCloseHandler}>
        <Loadable isLoading={isLoading}>
          {data && data.clientSecret ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: data.clientSecret,
              }}
            >
              <Typography text={'addCard'} tag="h2" variant="doubleXl/bold" className="capitalize text-grey" />
              <AddPaymentMethodModalContent clientSecret={data.clientSecret} onSubmit={onCloseHandler} />
            </Elements>
          ) : null}
        </Loadable>
      </BaseModal>
    </>
  );
};
