import { cn } from '@/lib/utils';
import { SvgIcon } from '@components/SvgIcon';
import { Tooltip } from '@components/Tooltip';
import { LocalizedText, Typography } from '@components/Typography';
import { FC, ReactNode } from 'react';

type DeepDiveInfoBlockProps = {
  header: LocalizedText;
  headerTooltip?: ReactNode;
  content: string;
  headerClassName?: string;
  contentClassName?: string;
};

export const DeepDiveInfoBlock: FC<DeepDiveInfoBlockProps> = ({
  header,
  headerTooltip,
  content,
  headerClassName,
  contentClassName,
}) => (
  <div className="flex flex-col gap-2">
    <div className="flex items-center gap-2">
      <Typography {...header} variant="xs/regular" className={cn('text-grey-900 first-letter:capitalize', headerClassName)} />
      {headerTooltip ? (
        <Tooltip content={headerTooltip} side="right" align="start">
          <SvgIcon name="HelpCircleGradientIcon" className='size-4'/>
        </Tooltip>
      ) : null}
    </div>
    <Typography
      arbitrary
      text={content}
      variant="xs/bold"
      className={cn('line-clamp-3 first-letter:capitalize text-grey-900', contentClassName)}
    />
  </div>
);
