import { cn } from '@/lib/utils';
import { BaseModal } from '@components/BaseModal/BaseModal';
import { Tooltip } from '@components/Tooltip';
import { ComponentProps, FC, MouseEventHandler, ReactElement, ReactNode } from 'react';

type IconButtonWithModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  content: ReactElement;
  icon: ReactNode;
  iconClassName?: string;
  disabled?: boolean;
  tooltip?: ReactNode;
  modalProps?: Pick<ComponentProps<typeof BaseModal>, 'className' | 'contentWrapperClassName'>;
};

export const IconButtonWithModal: FC<IconButtonWithModalProps> = ({
  isOpen,
  setIsOpen,
  content,
  modalProps,
  icon,
  iconClassName,
  disabled,
  tooltip,
}) => {
  const onCloseHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const onOpenModal: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <>
      <Tooltip content={tooltip} className="h-fit w-44 text-center" side="bottom" align="end" asChild>
        <button
          disabled={disabled}
          className={cn(
            'h-5 disabled:cursor-not-allowed disabled:opacity-50 motion-safe:transition-all motion-safe:delay-100 motion-safe:duration-200 motion-reduce:transition-none [&:not(:disabled)]:hover:scale-125',
            iconClassName,
          )}
          onClick={onOpenModal}
        >
          {icon}
        </button>
      </Tooltip>
      {isOpen ? (
        <BaseModal isOpen={isOpen} onCloseHandler={onCloseHandler} {...modalProps}>
          {content}
        </BaseModal>
      ) : null}
    </>
  );
};
