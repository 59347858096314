import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { ColumnSort } from '@tanstack/react-table';
import { TableDataResponse, TableElement, TableRequestBody } from './types';

type UseTableQueryArgs<T extends TableElement> = {
  queryOptions: Omit<
    UndefinedInitialDataOptions<TableDataResponse<T>, Error, TableDataResponse<T>, unknown[]>,
    'queryFn'
  >;
  fetcher: (options: { params: TableRequestBody }) => Promise<TableDataResponse<T>>;
  sorting: ColumnSort;
  afterFetchHook: (result: TableDataResponse<T>) => void;
  offset: number;
  limit: number;
};

export const useTableQuery = <T extends TableElement>({
  fetcher,
  sorting,
  offset,
  limit,
  afterFetchHook,
  queryOptions: { queryKey, ...options },
}: UseTableQueryArgs<T>) => {
  return useQuery({
    queryKey: [...queryKey, sorting, offset, limit],
    queryFn: async () => {
      const result = await fetcher({
        params: {
          sorting,
          offset,
          limit,
        },
      });

      afterFetchHook(result);

      return result;
    },
    ...options,
  });
};
