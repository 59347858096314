import { DeepDiveItemResponse } from '@/api/generated/Api';
import { Avatar } from '@components/Avatar';
import { InitialTableState } from '@components/Tables/types';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { DeepDiveItemQualification } from './DeepDiveItemQualification';

const columnHelper = createColumnHelper<DeepDiveItemResponse>();

export const useDeepDiveItemsTableInitialState = () => {
  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('companyImageUrl', {
          id: 'companyLogoUrl',
          cell: (info) => <Avatar src={info.getValue()} alt="company_logo" className="size-12" />,
          header: 'logo',
          minSize: 70,
          maxSize: 70,
          enableSorting: false,
        }),
        columnHelper.accessor('companyName', {
          id: 'companyName',
          cell: (info) => info.getValue(),
          header: 'company',
          minSize: 170,
          maxSize: 230,
          enableSorting: false,
          meta: {
            className: () => 'font-bold first-letter:capitalize',
          },
        }),
        columnHelper.accessor('aiReasoning', {
          id: 'aiReasoning',
          cell: (info) => info.getValue(),
          header: 'aiReasoning',
          minSize: 300,
          maxSize: 400,
          enableSorting: false,
          meta: {
            className: () => 'font-bold first-letter:capitalize',
            nestedClassName: () => 'line-clamp-3 truncate text-wrap',
          },
        }),
        columnHelper.accessor('status', {
          id: 'isSuitable',
          cell: (info) => <DeepDiveItemQualification status={info.getValue()} />,
          header: 'suitable',
          minSize: 70,
          maxSize: 70,
          enableSorting: false,
        }),
        columnHelper.accessor('description', {
          id: 'description',
          cell: (info) => info.getValue(),
          header: 'description_simple',
          minSize: 170,
          maxSize: 230,
          enableSorting: false,
          meta: {
            className: () => 'first-letter:capitalize',
            nestedClassName: () => 'line-clamp-3 text-wrap',
          },
        }),
      ] as ColumnDef<DeepDiveItemResponse>[],
    [],
  );

  return useMemo(
    () =>
      ({
        sorting: [{ id: 'name', desc: true }],
        columns,
      }) as InitialTableState<DeepDiveItemResponse>,
    [columns],
  );
};
